import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import format from "date-fns/format";
import LoopApiServiceContext from "../../contexts/LoopApiServiceContext";
import { Fade, ProgressBar } from "react-bootstrap";

const InsuranceCard = ({
  loading = false,
  policyEnabled = true,
  policyType,
  insurerName,
  downloadUrl,
  onClick,
  eCardName,
}) => {
  // const formatDate = (date) => {
  //   const timeInMilliSeconds = date * 1000;
  //   return format(new Date(timeInMilliSeconds), "dd/MM/yyyy");
  // };
  const [downloadedUrl, setDownloadedUrl] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const firebaseContext = useContext(LoopApiServiceContext);

  useEffect(() => {
    if (downloadedUrl) {
      window.open(downloadedUrl);
      setDownloadedUrl("");
      setShowProgress(false);
    }
  }, [downloadedUrl]);

  const downloadEcard = (fileName) => {
    setShowProgress(true);
    const storage = firebaseContext.storage;
    storage
      .ref("Ecards/" + fileName)
      .getDownloadURL()
      .then((url) => {
        setDownloadedUrl(url);
      })
      .catch((error) => {
        setDownloadedUrl("");
        setShowProgress(false);
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
          default:
            break;
        }
      });
  };

  return (
    <Wrapper
      // href={downloadUrl}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      {!eCardName ? (
        <div className="employee_policies">
          <IconContainer>
            <Icon>
              <CardIcon src="/assets/images/pdf-download.svg" alt="" />
            </Icon>
          </IconContainer>
          <ErrorText>
            Your insurance ecards will be available
            <br /> here when issued.
          </ErrorText>
        </div>
      ) : (
        <div className="insurance-card-container">
          <div className="employee_policies">
            <IconContainer>
              <Icon>
                <CardIcon src="/assets/images/pdf-download.svg" alt="" />
              </Icon>
            </IconContainer>
            <div className="details">
              <InsuranceCardName>{policyType}</InsuranceCardName>
              {insurerName && (
                <InsuranceCardValidUpto>{insurerName}</InsuranceCardValidUpto>
              )}
            </div>
            <Fade in={!showProgress}>
              <IconContainer>
                <Icon>
                  <CardIcon
                    src="/assets/images/download_btn.svg"
                    className="download_img_settings"
                    alt=""
                    onClick={() => downloadEcard(eCardName)}
                  />
                </Icon>
              </IconContainer>
            </Fade>
          </div>
          {showProgress && (
            <div className="insurance-progress-container">
              <ProgressBar
                variant="success"
                animated
                now={downloadedUrl ? 100 : 60}
              />
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default InsuranceCard;

const Wrapper = styled.a`
  border: 1px solid #eaecf1;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 20px 15px 20px 15px;
  // height: 77px;
  width: 93%;
  margin-bottom: 12px;
  text-align: left;
  background-color: #fff;
  /* cursor: pointer; */
  text-decoration: none !important;
  .details {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 15px;
  }
  .employee_policies {
    width: 100%;
    display: flex;
  }
  .insurance-progress-container {
    margin-top: 10px;
  }
  .insurance-card-container {
    width: 100%;
  }
  .download_img_settings {
    width: 13px;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  background-color: white;
`;

const CardIcon = styled.img`
  width: 100%;
`;

const InsuranceCardName = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #3e4a59;
`;

const InsuranceCardValidUpto = styled.span`
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #696969;
`;

const ErrorText = styled.span`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  color: #424242;
  opacity: 0.5;
  margin-left: 20px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  height: 120px;
  color: #000;
`;
