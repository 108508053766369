import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppConstants } from '../../common/AppConstants';
import { convertToDate } from '../../common/Utilities';
import { AddEmployeeForm } from '../../components/AddEmployeeForm';
import { AlertModal } from '../../components/AlertModal';
import { EmpDetailItem } from '../../components/EmpDetailItem';
import Navbar from '../../components/Navbar/Navbar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import { selectIsEditForm } from '../../redux/slices/addEmpFormSlice';
import { clearHrReqState, selectAddHrRequest } from '../../redux/slices/hrRequestSlice';
import { fetchHrUser, selectHrUser } from '../../redux/slices/usersSlice';
import './ViewEmployeeDetail.scss';

const ViewEmployeeDetail = () => {
    const userData = useSelector(selectHrUser);
    const [employeeData, setEmployeeData] = useState(null);
    const [dependentData, setDependentData] = useState([]);
    const isEditForm = useSelector(selectIsEditForm);
    const history = useHistory();
    const dispatch = useDispatch();
    const addHrRequest = useSelector(selectAddHrRequest);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (addHrRequest && addHrRequest.state === 'succeeded') {
            dispatch(clearHrReqState({ data: AppConstants.ADD }));
            setShowMessage(true);
        }
    }, [dispatch, addHrRequest]);

    useEffect(() => {
        if (userData?.data?.mobile) {
            console.log('HERERE');
            dispatch(
                fetchHrUser({
                    methodType: 'mobile',
                    userId: userData.data.mobile,
                    loginMethod: 'mobile'
                })
            );
        }
    }, []);

    return (
        <div>
            <Navbar />
            <div className='view-emp-container'>
                {addHrRequest.state === 'loading' && <Backdrop />}
                <div className='view-emp-inner-container'>
                    <div>
                        {/* <h4 style={{ marginTop: 12 }}> SKF Enrolment Portal – enrol by {employeeData?.ctc || 0}</h4> */}
                        <AddEmployeeForm
                            employeeData={userData?.data}
                            employeeBody={employeeData}
                            setEmployeeData={setEmployeeData}
                            dependentList={dependentData}
                            setDependentList={setDependentData}
                            type={'edit'}
                            parentEmpId={employeeData?.employeeId}
                            userType={!employeeData ? AppConstants.EMPLOYEE : AppConstants.DEPENDENT}
                        />
                    </div>
                </div>
                <AlertModal
                    subTitle={`Your request to add employee has been submitted successfully! Our customer service team will get in touch with you within 24 hours`}
                    buttonText1={'Ok'}
                    showModal={showMessage}
                    setShowModal={setShowMessage}
                    onPressButton1={() => {
                        setShowMessage(false);
                        history.goBack();
                    }}
                />
            </div>
            {userData?.state === 'loading' && <Backdrop />}
        </div>
    );
};

export default ViewEmployeeDetail;
