import { createSlice } from "@reduxjs/toolkit";

const initialOperation = {
  userId: null,
  operation: null,
  msg: null,
  error: false,
  success: false,
};

const AddEmpFormSlice = createSlice({
  name: "addEmpForm",
  initialState: {
    isEditForm: false,
    currentOperation: {
      ...initialOperation,
    },
    clearForm: false,
  },
  reducers: {
    setEditForm: (state, action) => {
      state.isEditForm = action.payload.data;
    },
    setCurrentOperation: (state, action) => {
      state.currentOperation = {
        ...state.currentOperation,
        ...action.payload.data,
      };
    },
    setClearForm: (state, action) => {
      state.clearForm = action.payload.data;
    },
    clearCurrentOperation: (state, action) => {
      state.currentOperation = {
        ...initialOperation,
      };
    },
  },
  extraReducers: {
    "common/cleanup": (state, action) => {
      state.isEditForm = false;
    },
  },
});

export const {
  setEditForm,
  setCurrentOperation,
  clearCurrentOperation,
  setClearForm,
} = AddEmpFormSlice.actions;
export const selectIsEditForm = (state) => state.addEmpForm.isEditForm;
export const selectCurrentOperation = (state) =>
  state.addEmpForm.currentOperation;
export const selectClearForm = (state) => state.addEmpForm.clearForm;

export default AddEmpFormSlice.reducer;
