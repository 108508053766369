import firebase, { auth } from "../firebase";
import { fetchHrUser } from "../redux/slices/usersSlice";
import { AppConstants } from "./AppConstants";

export const verifyEmailLink = (
  setLoading,
  firebaseDb,
  history,
  dispatch,
  setLoggedInUser
) => {
  setLoading(true);
  let email = "";
  let id = "";
  if (auth.isSignInWithEmailLink(window.location.href)) {
    email = window.localStorage.getItem(AppConstants.EMAIL_FOR_SIGNIN);
    id = window.localStorage.getItem(AppConstants.FIR_DOC_ID);
    if (email) {
      //TODO: Set email here to the database
      auth
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          setLoading(false);
          firebaseDb
            .collection("customEnrolmentUser")
            .doc(id)
            .update({ email: email })
            .then((result) => {
              // setLoggedInUser({ email });
              dispatch(
                fetchHrUser({
                  methodType: "email",
                  userId: email,
                  loginMethod: "",
                })
              );
              // history.push("/");
              window.localStorage.removeItem(AppConstants.FIR_DOC_ID);
            })
            .catch((error) => {
              console.log("email update err:", error);
            });
          window.localStorage.removeItem(AppConstants.EMAIL_FOR_SIGNIN);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error after signin:", error.message);
        });
    } else {
      setLoading(false);
    }
  }
  setLoading(false);
};

export const setLoopIdToken = (userId) => {
  let currentUser = firebase.auth().currentUser;

  let callback = null;
  let metadataRef = null;
  firebase.auth().onAuthStateChanged(async (user) => {
    // Remove previous listener.
    if (callback) {
      metadataRef.off("value", callback);
    }
    // On user login add new listener.
    if (user) {
      // Check if refresh is required.
      metadataRef = firebase
        .database()
        .ref("metadata/" + user.uid + "/refreshTime");
      callback = (snapshot) => {
        // Force refresh to pick up the latest custom claims changes.
        // Note this is always triggered on first call. Further optimization could be
        // added to avoid the initial trigger when the token is issued and already contains
        // the latest claims.
        user.getIdToken(true);
      };
      // Subscribe new listener to changes on that node.
      metadataRef.on("value", callback);
    }
  });

  firebase
    .functions("asia-south1")
    .httpsCallable("applicationService-addHRLoopId")
    .call({ userId: userId })
    .then((item) => {
      //Note: need to create a token in store in userSlice and set the token from a action created in userSlice.
      // let currentUser = firebase.auth().currentUser;
      // currentUser.reload();
      if (item) {
        currentUser && currentUser.reload();
        const currentUser1 = firebase.auth().currentUser;
      }
    })
    .catch((error) => {
      console.log("add loop error:", error);
    });
};

export const sentEmailVerificationLink = (
  email,
  setLoading,
  setVerificatonSent,
  docId
) => {
  let actionCodeSettings = {
    url: process.env.REACT_APP_REDIRECT_URL,
    handleCodeInApp: true,
  };
  auth
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      setLoading(false);
      setVerificatonSent(true);
      window.localStorage.setItem(AppConstants.EMAIL_FOR_SIGNIN, email);
      window.localStorage.setItem(AppConstants.FIR_DOC_ID, docId);
    })
    .catch((error) => {
      setLoading(false);
      console.log("error code:", error.code, " error msg:", error.message);
    });
};
