import React from 'react'
import { Table } from 'react-bootstrap'



const TableHeader = ({ header }) => (
  <thead>
    {header.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((header, colIndex) => (
          <th key={colIndex} colSpan={header.colSpan} rowSpan={header.rowSpan}
            className='text-center' width={header.width}>
            {header.label}
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

const TableRow = ({ rowData }) => (
  rowData.map((data, index) => (
    <tr key={index}>
      <td className={data.style}>{data.label}</td>
      {
        data.values.map((childData, index) => (
          <td
            key={index}
            colSpan={childData.colSpan}
            rowSpan={childData.rowSpan}
            className='text-center align-middle'
          >
            {childData.value}
          </td>
        ))
      }
    </tr>
  ))
);

function Opdtables({header, rowData}) {

  return (
    <div>
        <Table bordered>
            <TableHeader header={header}/>
            <tbody>
                <TableRow rowData={rowData}/>
            </tbody>
      </Table>
    </div>
  )
}

export default Opdtables