import React from "react";
import { Alert } from "react-bootstrap";

const Message = ({ variant, children, dismissible = true, onClose }) => {
  return (
    <Alert variant={variant} dismissible={dismissible} onClose={onClose}>
      {children}
    </Alert>
  );
};

Message.defaultProps = {
  variants: "info",
};

export default Message;
