import React from "react";
import Form from "react-bootstrap/Form";
import "./Dropdown.scss";

export const Dropdown = ({
  controlId,
  labelName,
  type,
  placeholder,
  value,
  onChange,
  options,
  feedbackType = "invalid",
  feedbackText,
  displayKey = "title",
  disabled = false,
  fieldKey,
  invalidText,
  isInvalid,
  textCapitalize = false,
}) => {
  return (
    <Form.Group controlId={controlId}>
      <div className="dropdown-label-name">{labelName}</div>
      <select
        className={`dropdown-container ${
          textCapitalize ? "text-capitalize" : ""
        }`}
        type={type}
        placeholder="Select"
        value={value || "Select"}
        onChange={(e) => {
          onChange(e.target.value, fieldKey);
        }}
        disabled={disabled}
        as="select"
        required
      >
        <option
          className={`${textCapitalize ? "text-capitalize" : ""}`}
          value=""
        >
          Select
        </option>
        {options.map((item) => (
          <option key={item.id} value={item[displayKey]}>
            {item[displayKey]}
          </option>
        ))}
      </select>

      {isInvalid ? (
        <Form.Text className="error-message">{invalidText}</Form.Text>
      ) : null}
      <Form.Control.Feedback type={feedbackType}>
        {feedbackText}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
