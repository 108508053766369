import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoopApiService from "../../services/network/LoopApiService";

const apiState = {
  data: null,
  state: "idle",
  error: "",
};

export const fetchUsers = createAsyncThunk(
  "userdata/getUsers",
  async ({ companyId }) => {
    const result = await LoopApiService.getUsers(companyId);
    return {
      userdata: result.userdata,
      dependents: result.dependents,
    };
  }
);

export const fetchHrUser = createAsyncThunk(
  "userdata/fetchHrUser",
  async ({ userId, methodType, loginMethod }) => {
    const result = await LoopApiService.getUserData(userId, methodType);
    return {
      data: result,
      methodType,
      loginMethod,
    };
  }
);

export const updateHrData = createAsyncThunk(
  "userdata/updateHrData",
  async ({ id, data }) => {
    const result = await LoopApiService.updateHrData(id, data);
    return {
      data: result,
    };
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: {},
    dependents: {},
    state: "idle",
    error: "",
    confirmationResult: null,
    userData: {
      ...apiState,
      loginMethod: "",
    },
    hrUpdate: {
      ...apiState,
    },
  },
  reducers: {
    setConfirmationResult(state, action) {
      state.confirmationResult = action.payload.data;
    },
    setClearHrData(state, action) {
      state.userData = {
        ...apiState,
      };
    },
    clearHrUpdate(state, action) {
      state.hrUpdate = { ...apiState };
    },
  },
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.users = action.payload.userdata;
      state.dependents = action.payload.dependents;
      state.status = "succeeded";
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchHrUser.pending]: (state, action) => {
      state.userData.state = "loading";
      state.userData.error = "";
      state.userData.loginMethod = "";
    },
    [fetchHrUser.fulfilled]: (state, action) => {
      state.userData.data = action.payload.data;
      state.userData.state = "succeeded";
      state.userData.loginMethod = action.payload.loginMethod;
      state.userData.error =
        action.payload.methodType === "mobile" && !action.payload.data
          ? "MOBILE_NOT_FOUND"
          : action.payload.methodType === "email" && !action.payload.data
          ? "EMAIL_NOT_FOUND"
          : "";
      state.userData.loginMethod = action.payload.loginMethod;
    },
    [fetchHrUser.rejected]: (state, action) => {
      state.userData.state = "failed";
      state.userData.error = action.error.message;
    },

    [updateHrData.pending]: (state, action) => {
      state.hrUpdate.state = "loading";
      state.hrUpdate.error = "";
    },
    [updateHrData.fulfilled]: (state, action) => {
      state.hrUpdate.data = action.payload.data;
      state.hrUpdate.state = "succeeded";
    },
    [updateHrData.rejected]: (state, action) => {
      state.hrUpdate.state = "failed";
      state.hrUpdate.error = action.error.message;
    },
    "common/cleanup": (state, action) => {
      state.userData = { ...apiState };
      state.hrUpdate = { ...apiState };
      state.users = null;
      state.error = "";
      state.state = "idle";
    },
  },
});

export const selectUsers = (state) => state.users.users;
export const selectDependents = (state) => state.users.dependents;
export const selectConfirmationResult = (state) =>
  state.users.confirmationResult;

export const { setConfirmationResult, setClearHrData, clearHrUpdate } =
  usersSlice.actions;
export const selectHrUser = (state) => state.users.userData;
export const selectUpdateUser = (state) => state.users.hrUpdate;

export default usersSlice.reducer;
