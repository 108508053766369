import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Backdrop from "../components/UI/Backdrop/Backdrop";
import Message from "../components/Message/Message";
import Navbar from "../components/Navbar/Navbar";

import firebase from "../firebase";
import { Link } from "react-router-dom";

import "./RegisterSingleUser.scss";
import { AlertModal } from "../components/AlertModal";
import { useAuth } from "../contexts/AuthContext";
import moment from "moment";
import { AppConstants } from "../common/AppConstants";

const RegisterSingleUser = ({ match }) => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [mobileNo, setMobileNo] = useState();
  const [userGender, setUserGender] = useState("Male");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [documentId, setDocumentId] = useState("");

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const { currentUser } = useAuth();
  const handleRegisterUser = (e) => {
    setShowMessage(true);
    e.preventDefault();

    const db = firebase.firestore();

    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    if (!fName || !mobileNo || !dateOfBirth) {
      setLoading(false);
      return setErrorMessage("Please fill the fields");
    }

    db.collection("users")
      .where("userId", "==", mobileNo)
      .get()
      .then(async (querySnapshot) => {
        let existUser = [];
        querySnapshot.forEach((doc) => {
          existUser.push(doc.data());
        });
        if (!existUser.length) {
          let firstName = fName;
          let lastName = lName;
          let mobile = mobileNo;
          let email = emailAddress ? emailAddress : null;
          let dob = dateOfBirth;
          let gender = userGender;
          let patientType = "membership";
          let subscriptionRules = getSubscriptionRules(patientType);

          let companyId = match.params.companyid;

          let ccRef = db.collection("user");
          let ccs = (
            await ccRef.where("userType", "==", "coordinator").get()
          ).docs.map((doc) => doc.id);
          let assignedCCId = ccs[Math.floor(Math.random() * ccs.length)];

          let input = {
            active: true,
            hrRequest: {
              userId: mobile,
              firstName: firstName,
              lastName: lastName,
              userType: "patient",
              gender: gender ? gender.toLowerCase() : null,
              dob: moment(dateOfBirth, "YYYY-MM-DD").format("DD/MM/YYYY"),
              email: email || null,
              createdAt: Date.now(),
              updatedAt: Date.now(),
              primaryUserId: null,
              active: true,
              patientType: patientType,
              employer: companyId,
              coordinatorId: assignedCCId,
              policyEnabled: false,
              policyPlanId: null,
              policyValidUpto: null,
              patientEcard: null,
              inviteCount: subscriptionRules.inviteCount,
              visitCount: subscriptionRules.visitCount,
              visitDurationLimit: subscriptionRules.visitDuration,
              chatDurationLimit: subscriptionRules.chatDuration,
              dependents: [],
              parentUserId: null,
            },
            employer: companyId,
            requestBy: currentUser.email,
            status: "pending",
            dependents: [],
            operation: AppConstants.ADD,
            userId: mobile,
          };
          db.collection("users")
            .add(input)
            .then((docRef) => {
              setLoading(false);
              setDocumentId(docRef.id);
              setSuccessMessage("User successfully added");
            })
            .catch((error) => {
              setLoading(false);
              setErrorMessage("Failed to add user. Please try again!");
            });
        } else {
          setLoading(false);
          setErrorMessage("User already exist");
        }
      })
      .catch((error) => {
        setLoading(false);

        setErrorMessage("Failed to add user. Please try again!");
      });
  };

  const getSubscriptionRules = (patientType) => {
    let rules = {};
    if (patientType === "membership") {
      rules.inviteCount = 3;
      rules.visitCount = null;
      rules.visitDuration = Date.now() + 365 * 24 * 60 * 60 * 1000;
      rules.chatDuration = Date.now() + 365 * 24 * 60 * 60 * 1000;
    } else if (patientType === "TMOP") {
      rules.inviteCount = 3;
      rules.visitCount = 0;
      rules.visitDuration = null;
      rules.chatDuration = Date.now() + 365 * 24 * 60 * 60 * 1000;
    }
    return rules;
  };

  return (
    <div>
      <Navbar />
      <div className="add-employee">
        <div className="container">
          {loading && <Backdrop />}

          <Link
            to="/"
            className="d-flex justify-content-between align-items-center mt-4"
            style={{
              color: "#0e4da4",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            {`< HR Dashboard`}
          </Link>

          <div className="main">
            <h4>Add a new employee</h4>

            {errorMessage && <Message variant="danger">{errorMessage}</Message>}
            {successMessage && (
              <Message variant="success">
                {successMessage}
                <Link
                  to={`/add-dependent/${documentId}/${mobileNo}/${fName}/${lName}`}
                >
                  <Button className="ml-4" variant="outline-success" size="sm">
                    Add dependent
                  </Button>
                </Link>
              </Message>
            )}

            <section className="form-container">
              <div className="field-box">
                <text className="label">
                  Account Holder First Name<span className="label-span">*</span>
                </text>
                <input
                  type="text"
                  required
                  style={styles.inputTextStyle("black")}
                  className="input-box"
                  onChange={(e) => setFName(e.target.value)}
                />
              </div>
              <div className="field-box">
                <text className="label">
                  Account Holder Last Name<span className="label-span">*</span>
                </text>
                <input
                  type="text"
                  required
                  style={styles.inputTextStyle("black")}
                  className="input-box"
                  onChange={(e) => setLName(e.target.value)}
                />
              </div>
              <div className="field-box">
                <text className="label">E-mail Address</text>
                <input
                  type="email"
                  className="input-box"
                  style={styles.inputTextStyle("black")}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
              <div className="field-box">
                <text className="label">
                  Account Holder Mobile Number
                  <span className="label-span">*</span>
                </text>
                <input
                  type="number"
                  required
                  style={styles.inputTextStyle("black")}
                  className="input-box"
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </div>
              <div className="field-box">
                <text className="label">
                  Account Holder Date of Birth
                  <span className="label-span">*</span>
                </text>
                <input
                  type="date"
                  required
                  style={styles.inputTextStyle(
                    dateOfBirth !== "" ? "black" : "rgba(62, 74, 89, 0.5)"
                  )}
                  className="input-box"
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>
              <div className="field-box">
                <text className="label">
                  Account Holder Gender<span className="label-span">*</span>
                </text>

                <select
                  name="gender"
                  id="gender"
                  style={styles.inputTextStyle("black")}
                  className="input-box"
                  onChange={(e) => setUserGender(e.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </section>
            <div className="form-info text-right">
              <text>
                <span className="span">*</span>
                Fields are mandatory
              </text>
            </div>

            <div className="text-center mt-3">
              <button
                type="button"
                className="upload-button"
                disabled={loading}
                // onClick={handleShow}
                onClick={handleRegisterUser}
              >
                Submit Employee Addition
              </button>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        subTitle={`Your request to add a new employee has been submitted successfully! Our customer service team will get in touch with you within 24 hours`}
        buttonText1={"Ok"}
        showModal={showMessage}
        setShowModal={setShowMessage}
        onPressButton1={() => {
          setShowMessage(false);
        }}
      />
    </div>
  );
};

const styles = {
  inputTextStyle: (dateOfBirth) => ({
    color: dateOfBirth,
  }),
};

export default RegisterSingleUser;
