import React from 'react';
import Form from 'react-bootstrap/Form';
import './TagOption.scss';
import { capitalizeFirstChar } from '../../../services/utils';

export const TagOption = ({
  value,
  onChange,
  placeholder,
  isInvalid,
  invalidText = 'Enter valid value',
  fieldKey = '',
  labelName,
  controlId,
  type = 'text',
  option = [],
  isCapFirstChar = false,
}) => {
  return (
    <Form.Group controlId={controlId}>
      {labelName ? (
        <Form.Label className='input-label-name'>{labelName}</Form.Label>
      ) : null}
      <div className='tag-container'>
        {option.map((item) => (
          <div
            key={item.name}
            className={`tag-item-container ${
              value.toLowerCase() === item.name.toLowerCase()
                ? 'tag-item-selected'
                : ''
            }`}
            onClick={() => {
              onChange(item.name, fieldKey);
            }}
          >
            {isCapFirstChar === true
              ? capitalizeFirstChar(item.name)
              : item.name}
          </div>
        ))}
      </div>
      {isInvalid ? (
        <Form.Text className='error-message'>{invalidText}</Form.Text>
      ) : null}
    </Form.Group>
  );
};
