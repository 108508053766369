export const slabSelectionHeader = [
  [
    { label: 'Sum Insured'},
    { label: 'Premium (Including GST) INR' , colSpan: 4 },
  ],
];

export const coverageHeader = [
    [
        {label: 'Cover', rowSpan: 2, width: '40%'},
        {label: 'Plan 1'},
        {label: 'Plan 2'},
        {label: 'Plan 3'},
    ],
    [
        {label: 'Reimbursement', colSpan:3 }
    ]
]

export const slabSelectionRowData = [
    { 
        label: 5000, 
        style: 'text-center',
        values: [
            { value: '3,133'}
        ]
    },
    {
        label: 10000, 
        style: 'text-center',
        values: [
            { value: '5,964'}
        ]
    },
    {
        label: 20000, 
        style: 'text-center',
        values: [
            { value: '11,622'}
        ]
    }
]

export const coverageRowData = [
    {
        label: `Consultations, Prescribed Pharmacy, Prescribed Diagnostics, Dental (Except cosmetic Procedures), Vision (Only prescribed lens and glass cost covered), Vaccination Cost`,
        style: 'text-wrap',
        values: [
            { value: 5000, rowSpan: 6},
            { value: 10000, rowSpan: 6},
            { value: 20000, rowSpan: 6}
        ]
    }
]




 



 



 

