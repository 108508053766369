import { Button, Modal } from 'react-bootstrap';
import './SuccessModal.css';
import CloseIcon from '@mui/icons-material/Close';

export const SuccessModal = ({ showModal, setShowModal, onHandleSubmit }) => {
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
      }}
      show={showModal}
      onHide={() => {}}
    >
      <div className='upload-container-success'>
        {/* <div className="upload-header">
          <span className="header-text">
            Successfully updated enrolment details, you can still update your
            details till your final enrolment date!
          </span>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowModal(false)}
          >
            <CloseIcon color={"#959FBE"} />
          </div>
        </div> */}
        <div className='upload-body-container-success'>
          <img
            style={{
              height: '100px',
              marginRight: '5px',
            }}
            src={`/assets/icons/ic_checkmark.svg`}
            alt='view user'
          />
          <span>
            Successfully updated enrolment details, you can still update your
            details till your final enrolment date!
          </span>
        </div>

        <div className='upload-footer'>
          <Button onClick={onHandleSubmit}>Okay</Button>
        </div>
      </div>
    </Modal>
  );
};

const LabelText = ({ label, text, bold = false, operation }) => {
  return (
    <div className='label-text-container'>
      <span className='emp-label-text'>{label}</span>
      <span
        className={`${
          bold ? 'emp-bold-text' : 'emp-normal-text'
        } text-capitalize`}
      >
        {text}
      </span>
    </div>
  );
};
