import React, { useState, useRef, useEffect } from "react";

import * as XLSX from "xlsx";
import firebase, { fieldValue } from "../firebase";
import "firebase/firestore";

import { Container, Row, Col } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import ModalComponent from "../components/Modal/Modal";
import Message from "../components/Message/Message";
import "./RegisterUsers.scss";

const RegisterUsers = ({ match }) => {
  var db = firebase.firestore();

  const [items, setItems] = useState([]);

  const [employer, setEmployer] = useState("");

  const [uploadableItems, setUploadableItems] = useState([]);

  const [invalidPhoneNumberItems, setInvalidPhoneNumberItems] = useState([]);
  const [invalidTypeItems, setInvalidTypeItems] = useState([]);

  const [noNamesPresentItems, setNoNamesPresentItems] = useState([]);

  const [invalidDateOfBirthItems, setInvalidDateOfBirthItems] = useState([]);

  const [genderNotDefinedItems, setGenderNotDefinedItems] = useState([]);

  const [relationshipAbsentItems, setRelationshipAbsentItems] = useState([]);

  const [sameMobileNosWithAcHolderItems, setSameMobileNosWithAcHolderItems] =
    useState([]);

  const [duplicatePhoneNumberItems, setDuplicatePhoneNumberItems] = useState(
    []
  );

  const [duplicateDependents, setDuplicateDependents] = useState([]);
  const [dependentsAcHolderNotFound, setDependentsAccountHolderNotFound] =
    useState([]);

  const [showTables, setShowTables] = useState(false);
  const [show, setShow] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [successMessage, setSuccussMessage] = useState("");
  const [noUniqueDataMessage, setNoUniqueDataMessage] = useState("");
  const [uploadedCount, setUploadedCount] = useState(0);
  const [alreadyExistingRecordsCount, setAlreadyExistingRecordsCount] =
    useState(0);
  const [newlyCreatedRecordsCount, setNewlyCreatedRecordsCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const uploadCount = useRef();
  uploadCount.current = uploadedCount;

  const existingRecordCount = useRef();
  existingRecordCount.current = alreadyExistingRecordsCount;

  const newlyAddedRecordCount = useRef();
  newlyAddedRecordCount.current = newlyCreatedRecordsCount;

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setErrorMessage("");
    setShow(true);
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsArrayBuffer(file);
      }

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
      filterItems();
    });
  };

  const uploadData = () => {
    const db = firebase.firestore();

    let uploadableItemsLength = uploadableItems.length;
    const upload = () => {
      setIsUploading(true);

      uploadableItems.map(async (item) => {
        if (item.type === "Corporate") {
          db.collection("users")
            .where("userId", "==", item.mobile)
            .get()
            .then(async (querySnapshot) => {
              let existUser = [];
              querySnapshot.forEach((doc) => {
                existUser.push(doc.data());
              });
              if (!existUser.length) {
                let name = item.name;
                let firstName = name.split(" ").slice(0, -1).join(" ");
                let lastName = name.split(" ").slice(-1).join(" ");
                let mobile = item.mobile;
                let email = item.email ? item.email : null;
                let dob = item.date_of_birth;
                let gender = item.gender;
                let patientType = item.patientType
                  ? item.patientType
                  : "membership";
                let subscriptionRules = getSubscriptionRules(patientType);

                // const companyRef = db.collection("company").doc(companyId);
                // const company = await companyRef.get();

                let companyId;

                companyId = match.params.companyid;

                let ccRef = db.collection("user");
                let ccs = (
                  await ccRef.where("userType", "==", "coordinator").get()
                ).docs.map((doc) => doc.id);
                let assignedCCId = ccs[Math.floor(Math.random() * ccs.length)];

                let input = {
                  userId: mobile,
                  firstName: firstName,
                  lastName: lastName,
                  userType: "patient",
                  gender: gender ? gender.toLowerCase() : null,
                  dob: dob || null,
                  email: email || null,
                  createdAt: Date.now(),
                  updatedAt: Date.now(),
                  primaryUserId: null,
                  active: true,
                  patientType: patientType,
                  employer: companyId,
                  coordinatorId: assignedCCId,
                  policyEnabled: false,
                  policyPlanId: null,
                  policyValidUpto: null,
                  patientEcard: null,
                  inviteCount: subscriptionRules.inviteCount,
                  visitCount: subscriptionRules.visitCount,
                  visitDurationLimit: subscriptionRules.visitDuration,
                  chatDurationLimit: subscriptionRules.chatDuration,
                  dependents: [],
                  parentUserId: null,
                };

                db.collection("users")
                  .add(input)
                  .then((docRef) => {
                    setUploadedCount(uploadCount.current + 1);
                    setNewlyCreatedRecordsCount(
                      newlyAddedRecordCount.current + 1
                    );
                    createDependents(item.mobile, assignedCCId);
                  })
                  .catch((error) => {
                    console.error("Error adding document: ", error);
                    setErrorMessage("Failed to upload. Please try again!");
                  });
              } else {
                let ccRef = db.collection("user");
                let ccs = (
                  await ccRef.where("userType", "==", "coordinator").get()
                ).docs.map((doc) => doc.id);
                let assignedCCId = ccs[Math.floor(Math.random() * ccs.length)];
                setUploadedCount(uploadCount.current + 1);
                setAlreadyExistingRecordsCount(existingRecordCount.current + 1);
                createDependents(item.mobile, assignedCCId);
              }
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
              setErrorMessage("Failed to upload. Please try again!");
            });
        }
      });
    };

    const getSubscriptionRules = (patientType) => {
      let rules = {};
      if (patientType === "membership") {
        rules.inviteCount = 3;
        rules.visitCount = null;
        rules.visitDuration = Date.now() + 365 * 24 * 60 * 60 * 1000;
        rules.chatDuration = Date.now() + 365 * 24 * 60 * 60 * 1000;
      } else if (patientType === "TMOP") {
        rules.inviteCount = 3;
        rules.visitCount = 0;
        rules.visitDuration = null;
        rules.chatDuration = Date.now() + 365 * 24 * 60 * 60 * 1000;
      }
      return rules;
    };

    const createDependents = (mobileNo, assignedCCId) => {
      uploadableItems.forEach(async (item) => {
        if (
          item.type === "Policy Dependents" &&
          item.account_holder_mobile_no === mobileNo
        ) {
          let id = item.account_holder_mobile_no + item.name;
          let name = item.name;
          let firstName = name.split(" ").slice(0, -1).join(" ");
          let lastName = name.split(" ").slice(-1).join(" ");

          db.collection("users")
            .where("parentUserId", "==", item.account_holder_mobile_no)
            .where("firstName", "==", firstName)
            .where("lastName", "==", lastName)
            .get()
            .then((querySnapshot) => {
              let existUser = [];
              querySnapshot.forEach((doc) => {
                existUser.push(doc.data());
              });
              if (!existUser.length) {
                let input = {
                  userId: id,
                  firstName: name.split(" ").slice(0, -1).join(" "),
                  lastName: name.split(" ").slice(-1).join(" "),
                  gender: item.gender,
                  dob: item.date_of_birth,
                  contact: item.mobile ? item.mobile : null,
                  createdAt: Date.now(),
                  parentUserId: mobileNo,
                  patientType: "dependent",
                  coordinatorId: assignedCCId,
                  patientEcard: null,
                  relationship: item.relationship_to_account_holders,
                };

                db.collection("users")
                  .add(input)
                  .then((docRef) => {
                    db.collection("users")
                      .where("userId", "==", item.account_holder_mobile_no)
                      .get()
                      .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                          // doc.data() is never undefined for query doc snapshots
                          const parentUser = db.collection("users").doc(doc.id);
                          parentUser.update({
                            dependents: fieldValue.arrayUnion(docRef.id),
                            updatedAt: Date.now(),
                          });
                        });
                      })
                      .catch((error) => {
                        console.log("Error getting documents: ", error);
                        setErrorMessage("Failed to upload. Please try again!");
                      });

                    setUploadedCount(uploadCount.current + 1);
                    setNewlyCreatedRecordsCount(
                      newlyAddedRecordCount.current + 1
                    );
                    if (uploadCount.current === uploadableItemsLength) {
                      setIsUploading(false);
                      setShow(false);

                      if (newlyAddedRecordCount.current) {
                        setSuccussMessage(
                          `Unique data found. ${newlyAddedRecordCount.current} new rows added`
                        );
                      } else {
                        setNoUniqueDataMessage(
                          "No unique data found. No new rows added"
                        );
                      }
                    }

                    if (newlyAddedRecordCount.current) {
                      setSuccussMessage(
                        `Unique data found. ${newlyAddedRecordCount.current} new rows added`
                      );
                    } else {
                      setNoUniqueDataMessage(
                        "No unique data found. No new rows added"
                      );
                    }
                  })
                  .catch((error) => {
                    console.error("Error adding dependent", error);
                    setErrorMessage("Failed to upload. Please try again!");
                  });
              } else {
                setUploadedCount(uploadCount.current + 1);
                setAlreadyExistingRecordsCount(existingRecordCount.current + 1);
                if (uploadCount.current === uploadableItemsLength) {
                  setIsUploading(false);
                  setShow(false);

                  setSuccussMessage(
                    `${newlyAddedRecordCount.current} users registered. ${existingRecordCount.current} users already exist and were not registered.`
                  );

                  // if (newlyAddedRecordCount.current) {
                  //   setSuccussMessage(
                  //     `Unique data found. ${newlyAddedRecordCount.current} new rows added`
                  //   );
                  // } else {
                  //   console.log("yesh");
                  //   setNoUniqueDataMessage(
                  //     "No unique data found. No new rows added"
                  //   );
                  // }
                }
              }
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
              setErrorMessage("Failed to upload. Please try again!");
            });
        }
      });
    };
    upload();
  };

  useEffect(() => {
    filterItems();
    setSuccussMessage("");
    setNoUniqueDataMessage("");
  }, [items, db]);

  const filterItems = () => {
    uploadableItems.splice(0, uploadableItems.length);
    invalidPhoneNumberItems.splice(0, invalidPhoneNumberItems.length);
    invalidTypeItems.splice(0, invalidTypeItems.length);
    noNamesPresentItems.splice(0, noNamesPresentItems.length);
    invalidDateOfBirthItems.splice(0, invalidDateOfBirthItems.length);
    genderNotDefinedItems.splice(0, genderNotDefinedItems.length);
    relationshipAbsentItems.splice(0, relationshipAbsentItems.length);
    sameMobileNosWithAcHolderItems.splice(
      0,
      sameMobileNosWithAcHolderItems.length
    );
    duplicatePhoneNumberItems.splice(0, duplicatePhoneNumberItems.length);
    duplicateDependents.splice(0, duplicateDependents.length);
    dependentsAcHolderNotFound.splice(0, dependentsAcHolderNotFound.length);
    setSuccussMessage("");

    items.map((item) => {
      if (
        item.type === "Corporate" &&
        (!item.mobile || item.mobile.toString().length !== 10)
      ) {
        item.error = "Mobile no. absent/invalid";
        return invalidPhoneNumberItems.push(item);
      } else if (
        item.type === "Policy Dependents" &&
        (!item.account_holder_mobile_no ||
          item.account_holder_mobile_no.toString().length !== 10)
      ) {
        item.error = "Account Hol. mobile no. absent/invalid";
        return invalidPhoneNumberItems.push(item);
      } else if (item.type === "Policy Dependents" && !item.name) {
        item.error = "Dependents name not present";
        return noNamesPresentItems.push(item);
      } else if (item.type === "Policy Dependents" && !item.gender) {
        item.error = "Dependents gender is Missing";
        return genderNotDefinedItems.push(item);
      } else if (
        item.type === "Policy Dependents" &&
        !item.relationship_to_account_holders
      ) {
        item.error = "Dependents relationship to account holders not defined";
        return relationshipAbsentItems.push(item);
      } else if (
        item.type === "Policy Dependents" &&
        item.mobile === item.account_holder_mobile_no
      ) {
        item.error = "Dependent Contact number is same as Ac Holder";
        return sameMobileNosWithAcHolderItems.push(item);
      } else if (item.type === "Policy Dependents" && !item.date_of_birth) {
        item.error = "DOB is Missing";
        return invalidDateOfBirthItems.push(item);
      } else if (
        item.type !== "Policy Dependents" &&
        item.type !== "Corporate"
      ) {
        return invalidTypeItems.push(item);
      } else {
        if (item.type === "Corporate" && uploadableItems.length) {
          uploadableItems.map((i, index) => {
            if (i.mobile === item.mobile) {
              item.error = "Duplicate Phone Number found";
              i.error = "Duplicate Phone Number found";
              uploadableItems.splice(index, 1);
              duplicatePhoneNumberItems.push(i);
              return duplicatePhoneNumberItems.push(item);
            }
            return null;
          });
        }
        if (item.type === "Policy Dependents" && items.length) {
          let foundOne = false;
          items.map((i, index) => {
            if (
              i.type === "Corporate" &&
              i.mobile === item.account_holder_mobile_no
            ) {
              return (foundOne = true);
            }
            return null;
          });
          if (!foundOne) {
            item.error = "No Account Holder found for Dependents";
            return dependentsAcHolderNotFound.push(item);
          }
        }
        if (item.type === "Policy Dependents" && uploadableItems.length) {
          uploadableItems.map((i, index) => {
            if (
              i.account_holder_mobile_no === item.account_holder_mobile_no &&
              i.name === item.name
            ) {
              item.error = "Duplicate Dependents";
              i.error = "Duplicate Dependents";
              uploadableItems.splice(index, 1);
              duplicateDependents.push(i);
              return duplicateDependents.push(item);
            }
            return null;
          });
        }

        return uploadableItems.push(item);
      }
    });

    setShowTables(!showTables);
  };

  const rows = items
    ? items.map((item, index) => {
        return { id: index, ...item };
      })
    : null;

  const columns = [
    {
      field: "error",
      headerName: "ERROR",
      width: 250,
      cellClassName: (params) =>
        clsx("super-app", {
          positive: params.value,
        }),
    },
    { field: "name", headerName: "NAME", width: 150 },
    { field: "type", headerName: "TYPE", width: 150 },
    { field: "mobile", headerName: "MOBILE", width: 120 },
    { field: "account_holder", headerName: "AC HOLDER NAME", width: 150 },
    {
      field: "account_holder_mobile_no",
      headerName: "AC HOLDER Mob. No.",
      width: 150,
    },

    { field: "gender", headerName: "GENDER", width: 100 },
    { field: "date_of_birth", headerName: "DOB", width: 120 },
  ];

  const classes = useStyles();

  return (
    <div className="register-users">
      <Container className="pt-5 pb-5">
        {/* <button onClick={testUpload}>test Upload</button> */}
        <Row className="align-items-centern">
          <Col>
            <h4>
              <b>Bulk Register Members</b>
            </h4>
          </Col>
        </Row>

        <div className="input-area">
          {match.params.companyid && (
            <div className="input-group">
              <input
                type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
            </div>
          )}
        </div>

        <div
          style={{
            height: 590,
            width: "100%",
            margin: "auto",
            marginTop: "30px",
          }}
          className={classes.root}
        >
          {uploadableItems.length ? (
            <DataGrid
              rowHeight={42}
              rows={rows}
              columns={columns}
              pageSize={11}
            />
          ) : null}
        </div>

        {uploadableItems.length && !successMessage && !noUniqueDataMessage ? (
          <>
            <div className="mt-5 mb-4 text-center">
              {errorMessage && (
                <Message variant="danger">{errorMessage}</Message>
              )}
            </div>

            <div className="valid-data-count-message">
              {uploadableItems.length} users have correct data. Issues found
              with {items.length - uploadableItems.length} users' data.
            </div>

            <div className="text-center mt-4">
              <button
                type="button"
                className="upload-button"
                disabled={!uploadableItems.length}
                onClick={handleShow}
              >
                Upload to database
              </button>
            </div>
          </>
        ) : uploadableItems.length && successMessage && !noUniqueDataMessage ? (
          <div className="mt-5 text-center">
            <Message variant="success">{successMessage}</Message>
          </div>
        ) : uploadableItems.length && !successMessage && noUniqueDataMessage ? (
          <div className="mt-5 text-center">
            <Message variant="warning">{noUniqueDataMessage}</Message>
          </div>
        ) : null}

        <ModalComponent
          uploading={isUploading}
          show={show}
          handleClose={handleClose}
          length={uploadableItems.length}
          uploadedItemsCount={uploadCount.current}
          uploadableItemsCount={uploadableItems.length}
          uploadHandler={uploadData}
          employer={employer}
        />
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    "& .super-app.positive": {
      backgroundColor: "rgba(245,72,127,0.1)",

      fontWeight: "600",
    },
  },
});

export default RegisterUsers;
