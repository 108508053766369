import { AppConstants } from "./AppConstants";
import { customAlphabet } from "nanoid";
import moment from "moment";

export const getStatus = (operation, prefix = null) => {
  switch (operation) {
    case AppConstants.ADD:
      return `${prefix ? prefix + " " : ""}Addition Pending`;
    case AppConstants.UPDATE:
      return `${prefix ? prefix + " " : ""}Update Pending`;
    case AppConstants.DELETE:
      return `${prefix ? prefix + " " : ""}Deletion Pending`;
    case AppConstants.ADD_DEPENDENT:
      return "Dependent Addition Pending";
    case AppConstants.UPDATE_DEPENDENT:
      return "Dependent Update Pending";
    case AppConstants.DELETE_DEPENDENT:
      return "Dependent Delete Pending";
    default:
      return null;
  }
};

export const getSuccessMessage = (firstName, lastName, operation) => {
  switch (operation) {
    case AppConstants.ADD:
      return `Your request to add employee information for ${
        firstName + " " + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    case AppConstants.ADD_DEPENDENT:
      return `Your request to add dependent information for ${
        firstName + " " + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    case AppConstants.UPDATE:
      return `Your request to update employee information for ${
        firstName + " " + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    case AppConstants.UPDATE_DEPENDENT:
      return `Your request to update dependent information for ${
        firstName + " " + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    default:
      break;
  }
};

export const validateMobile = (value) => {
  if (!value || value.length !== 10) {
    return null;
  }
  var regex = /^[0-9]+$/;
  return regex.test(value);
};

export const validateEmail = (emailTxt) => {
  if (!emailTxt) {
    return null;
  }
  // var regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var regEx = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
  return regEx.test(emailTxt);
};

export const validateName = (value) => {
  if (!value) {
    return null;
  }
  const regEx = /^[a-zA-Z ]+$/;
  return regEx.test(value);
};

export const getLoopId = (prefix = "LPU") => {
  let nanoid_6 = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 6);
  let documentId = `${prefix}-` + nanoid_6().toLocaleUpperCase();
  return documentId;
};

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}
const convertToISOFormat = (date) => {
  let nonISODateObject = new Date(date.toISOString());
  let isoDateObject =
    nonISODateObject.getFullYear() +
    "-" +
    (nonISODateObject.getMonth() + 1) +
    "-" +
    nonISODateObject.getDate();
  return isoDateObject;
};
//converts yyyy-mm-dd into timestamp
export const convertToTimestamp = (date) => {
  if (!date) {
    return null;
  }
  const [year, month, day] = date.split("-");
  const dobTimeStamp = new Date(year, month - 1, day);
  if (!month) {
    return ExcelDateToJSDate(parseInt(date, 10));
  }
  return convertToISOFormat(dobTimeStamp);
};
//converts timestamp into yyyy-mm-dd
export const convertToDate = (data, dateFormat = "YYYY-MM-DD") => {
  if (!data) {
    return null;
  }
  let date = "";
  let dateData = data.seconds || data._seconds;
  if (dateData) {
    date = moment.unix(dateData).format(dateFormat);
  } else if (data) {
    date = moment.unix(data).format(dateFormat);
  }
  return date;
};

// Get amount text
export const convertPrice = (value, isGst = false) => {
  if (!value || parseInt(value) === 0) {
    return "-";
  }
  return `₹ ${parseInt(value).toLocaleString("en-In")}${
    isGst ? " (incl GST)" : ""
  }`;
};
