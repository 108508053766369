import React, { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { sentEmailVerificationLink } from "../../common/CustomFirebase";
import { validateEmail, validateMobile } from "../../common/Utilities";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import LoopApiServiceContext from "../../contexts/LoopApiServiceContext";
import { auth } from "../../firebase";
import {
  selectConfirmationResult,
  selectHrUser,
  setConfirmationResult,
} from "../../redux/slices/usersSlice";
import { SideLayout } from "../../components/UI/SideLayout";

import "../Login.scss";
const EnterAuth = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [verfiyMobile, setVerifyMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const showMail = (location.state && location.state.showEmail) || false;
  const [userId, setUserId] = useState(null);
  const firebaseContext = useContext(LoopApiServiceContext);
  const [verificationSent, setVerificatonSent] = useState(false);
  const selectHrData = useSelector(selectHrUser);

  useEffect(() => {
    window.recaptchaVerifier = new firebaseContext.captcha.RecaptchaVerifier(
      "otp-button",
      {
        size: "invisible",
      }
    );
  }, [
    firebaseContext.auth.RecaptchaVerifier,
    firebaseContext.captcha.RecaptchaVerifier,
  ]);

  const confirmationResult = useSelector(selectConfirmationResult);

  useEffect(() => {
    if (confirmationResult && userId && userId.length === 10 && verfiyMobile) {
      history.push("/enter-otp", {
        mobile: userId,
        verifyEmail: false,
      });
      setVerifyMobile(false);
    }
  }, [confirmationResult, history, userId, verfiyMobile]);

  const sendVerifyLink = async () => {
    setLoading(true);
    if (showMail) {
      sentEmailVerificationLink(
        userId,
        setLoading,
        setVerificatonSent,
        selectHrData.data.id
      );
    } else {
      try {
        const confirmationData = await auth.signInWithPhoneNumber(
          "+91" + userId,
          window.recaptchaVerifier
        );
        dispatch(setConfirmationResult({ data: confirmationData }));
        setVerifyMobile(true);
        setLoading(false);
      } catch (error) {
        console.log("phone auth error:", error);
      }
    }
  };

  return (
    <>
      <div className="login-container">
        {loading && <Backdrop />}
        <SideLayout />
        <div className="login right-box-content">
          {!verificationSent ? (
            <div className="lh-login-form">
              <h4 className="login-title">
                Login Successful <img src="/assets/images/success.svg" alt="" />
              </h4>
              <p className="intro-subtitle mb-4">
                Kindly help us with the required contact information. We will
                only use this information to send you reports and policy
                details.
              </p>

              <input
                className="login-input"
                placeholder={`Enter ${showMail ? "email" : "phone number"}`}
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
              />

              <Button
                id="otp-button"
                className="lh-login-submit-button"
                onClick={sendVerifyLink}
                disabled={
                  showMail ? !validateEmail(userId) : !validateMobile(userId)
                }
              >
                Verify
              </Button>
            </div>
          ) : (
            <div className="login">
              <h4 className="login verify-heading">Verify Email</h4>
              <span className="login verify-body">
                We’ve shared a verfication link on{" "}
                <span className="login verify-email">{userId}</span>{" "}
                <img
                  onClick={() => setVerificatonSent(false)}
                  src="/assets/images/edit.svg"
                  alt=""
                />
                <br />
                Please click on the verification link provided in the email
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EnterAuth;
