import React from "react";
import { Button, Modal } from "react-bootstrap";

function AlertModal({
  title,
  subTitle,
  buttonText1,
  onPressButton1,
  showModal,
  setShowModal,
  variant = "success",
  buttonText2,
  onPressButton2,
}) {
  return (
    <Modal show={showModal} onHide={setShowModal}>
      {title ? (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      ) : null}
      {subTitle ? <Modal.Body>{subTitle}</Modal.Body> : null}
      <Modal.Footer>
        {buttonText2 ? (
          <Button variant={"success"} onClick={onPressButton2}>
            {buttonText2}
          </Button>
        ) : null}
        {buttonText1 ? (
          <Button variant={variant} onClick={onPressButton1}>
            {buttonText1}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

export { AlertModal };
