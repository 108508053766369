export const DropdownData = {
    relationShip: [
        {
            id: 1,
            name: 'Spouse'
        },
        {
            id: 2,
            name: 'Child'
        },
        {
            id: 3,
            name: 'Father'
        },
        {
            id: 4,
            name: 'Mother'
        }
    ],
    genderData: [
        {
            id: 0,
            name: 'male'
        },
        {
            id: 1,
            name: 'female'
        }
    ],
    bloodGroup: [
        {
            id: 0,
            name: 'A+'
        },
        {
            id: 1,
            name: 'A-'
        },
        {
            id: 2,
            name: 'AB+'
        },
        {
            id: 3,
            name: 'AB-'
        },
        {
            id: 4,
            name: 'B+'
        },
        {
            id: 5,
            name: 'B-'
        },
        {
            id: 6,
            name: 'O+'
        },
        {
            id: 7,
            name: 'O-'
        }
    ],
    topUpDetails: (sumInsured) => {
        return [
            {
                id: 0,
                name: 200000,
                annualPremium: sumInsured === 500000 ? 7628 : 4046
            },
            {
                id: 1,
                name: 300000,
                annualPremium: sumInsured === 500000 ? 8513 : 4594
            },
            {
                id: 2,
                name: 500000,
                annualPremium: sumInsured === 500000 ? 9843 : 5032
            },
            {
                id: 3,
                name: 700000,
                annualPremium: sumInsured === 500000 ? 11295 : 5390
            },
            {
                id: 4,
                name: 1000000,
                annualPremium: sumInsured === 500000 ? 20696 : 11149
            },
            {
                id: 5,
                name: 1500000,
                annualPremium: sumInsured === 500000 ? 31424 : 17065
            }
        ];
    },
    parentAllData: [
        {
            id: 0,
            name: 'father'
        },
        {
            id: 1,
            name: 'mother'
        },
        {
            id: 2,
            name: 'father in law'
        },
        {
            id: 3,
            name: 'mother in law'
        }
    ],
    parentData: [
        {
            id: 0,
            name: 'father'
        },
        {
            id: 1,
            name: 'mother'
        }
    ],
    parentInLaw: [
        {
            id: 0,
            name: 'father in law'
        },
        {
            id: 1,
            name: 'mother in law'
        }
    ],
    topUpData: [
        {
            id: 0,
            name: 1
        },
        {
            id: 1,
            name: 2
        }
    ],
    gtlTopUpData: [
        {
            id: 0,
            name: 1
        }
    ],
    parentTypeData: [
        {
            id: 0,
            name: 'Parent'
        },
        {
            id: 1,
            name: 'Parent-in-law'
        }
    ],
    opdPolicy: [
        {
            id: 0,
            name: '5000'
        },
        {
            id: 0,
            name: '10000'
        },
        {
            id: 0,
            name: '20000'
        }
    ]
};
