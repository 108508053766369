import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { selectHrUser } from "../redux/slices/usersSlice";

export function PrivateRoute({ component: Component, ...rest }) {
  const userData = useSelector(selectHrUser);

  return (
    <Route
      {...rest}
      render={(props) => {
        return userData &&
          userData.data &&
          (userData.data.email ||
          userData.data.mobile) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    ></Route>
  );
}
