import { Form } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './AddEmployeeForm.scss';
import { toast } from 'react-toastify';

import { FormInput } from '../UI/FormInput/FormInput';
import { Dropdown } from '../UI/Dropdown';
import { DropdownData } from '../../common/Dropdowndata';
import { TagOption } from '../UI/tagOption/TagOption';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';

import { fetchHrUser, selectHrUser } from '../../redux/slices/usersSlice';
import {
  clearEnrolmentState,
  postEnrolmentData,
  selectEnrolmentData,
} from '../../redux/slices/hrRequestSlice';
import { AppConstants } from '../../common/AppConstants';
import {
  convertToDate,
  validateEmail,
  validateMobile,
  validateName,
} from '../../common/Utilities';
import moment from 'moment';
import LoopApiServiceContext from '../../contexts/LoopApiServiceContext';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { SummaryData } from '../SummaryData/SummaryData';
import { useAuth } from '../../contexts/AuthContext';
import { SuccessModal } from '../UI/successModal/SuccessModal';
import { cleanup } from '../../redux/store';
import {
  caluclateAgeWiseRatePerMil,
  handleGTLTopUpCalculation,
  handleOPDPremiumValues,
} from './constant';
import {
  coverageHeader,
  coverageRowData,
  slabSelectionHeader,
  slabSelectionRowData,
} from './OpdPolicy/constant';
import Opdtables from './OpdPolicy/Opdtables';
import { capitalizeFirstChar } from '../../services/utils';
export const AddEmployeeForm = ({
  setEmployeeData,
  setDependentList,
  employeeData,
  dependentList,
  setVisibleForm,
  isDependent,
  index,
  operation = AppConstants.ADD,
  empDocRef = null,
  onGetFormData = null,
  userType,
  mode = AppConstants.ADD_MODE,
  parentEmpId,
  employeeBody,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectHrUser);
  const history = useHistory();
  const { logout, setLoggedInUser } = useAuth();
  const [showSummary, setShowSummary] = useState(false);
  const enrolmentData = useSelector(selectEnrolmentData);
  const [checkAddress, setCheckAddress] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [opdValue, setOpdValue] = useState(0);
  const onChangeOPDValue = (value, key) => {
    setOpdValue(value);
  };
  //const values
  const parentalCost = 14742;
  const singleParentalCost = 7371;

  //Note: In below declaration if the form is for edit and operation needed to be add then we'll find the value from array.
  const empData = employeeData || null;
  const [gpaMultiplier, setGPAMultiplier] = useState(empData?.riskRole ? 0 : 0);
  const [gtlMultiplier, setGTLMultiplier] = useState(empData?.riskRole ? 0 : 0);
  const parentData = employeeData?.dependents?.length
    ? employeeData.dependents.filter(
        (dependent) =>
          dependent.relationship === 'father' ||
          dependent.relationship === 'mother'
      )
    : null;
  const parentInLawData = employeeData?.dependents?.length
    ? employeeData.dependents.filter(
        (dependent) =>
          dependent.relationship === 'mother in law' ||
          dependent.relationship === 'father in law'
      )
    : null;
  const parentDependents = employeeData?.dependents?.length
    ? employeeData.dependents.filter(
        (dependent) =>
          dependent.relationship === 'father' ||
          dependent.relationship === 'mother' ||
          dependent.relationship === 'mother in law' ||
          dependent.relationship === 'father in law'
      )
    : null;
  const childDependents = employeeData?.dependents?.length
    ? employeeData.dependents.filter(
        (dependent) =>
          dependent.relationship === 'daughter' ||
          dependent.relationship === 'son'
      )
    : null;
  //Note: By Default employee relation value would be Self at the time of registering.
  const spouse = employeeData?.dependents?.length
    ? employeeData?.dependents.find((item) => item.relationship === 'spouse')
    : null;
  const child1 = childDependents?.[0] || null;
  const child2 = childDependents?.[1] || null;
  const child3 = childDependents?.[2] || null;

  const parent1 = parentDependents?.[0] || null;
  const parent2 = parentDependents?.[1] || null;
  const [formData, setFormData] = useState({
    name: empData ? empData.name : '',
    lastName: empData ? empData.lastName : '',
    address: empData ? empData.address : '',
    bloodGroup: empData ? empData.bloodGroup : '',
    emergencyContactName: empData?.emergencyContactName || '',
    emergencyContactMobile: empData?.emergencyContactMobile || '',
    emergencyContactAddress: empData?.emergencyContactAddress || '',
    relationship:
      empData && empData.relationship
        ? empData.relationship
        : isDependent
        ? ''
        : 'Self',
    canDeleteParent: empData.canDeleteParent || false,
    gender: empData?.gender || '',
    mobile: empData?.mobile || '',
    dob: empData ? empData.dob : '',
    email: empData?.email || '',
    employeeId: empData?.employeeId || '',
    spouseName: spouse?.name || '',
    spouseDob: spouse?.dob || '',
    spouseGender: spouse?.gender || '',

    childName1: child1?.name || '',
    childDob1: child1?.dob || '',
    childGender1: child1?.gender || '',
    childRelationship1: child1?.relationship || '',

    childName2: child2?.name || '',
    childDob2: child2?.dob || '',
    childGender2: child2?.gender || '',
    childRelationship2: child2?.relationship || '',

    childName3: child3?.name || '',
    childDob3: child3?.dob || '',
    childGender3: child3?.gender || '',
    childRelationship3: child3?.relationship || '',

    parentName1: parent1?.name || '',
    parentDob1: parent1?.dob || '',
    parentGender1: parent1?.gender || '',
    parentRelationship1: parent1?.relationship || '',

    parentName2: parent2?.name || '',
    parentDob2: parent2?.dob || '',
    parentGender2: parent2?.gender || '',
    parentRelationship2: parent2?.relationship || '',

    sumInsured: empData?.sumInsured || '',
    topupAmount: null,
  });

  let noOfParentsFetched =
    formData.parentName1 && formData.parentName2
      ? 2
      : formData.parentName1 || formData.parentName2
      ? 1
      : 0;
  const [parentDataOption, setParentDataOption] = useState(
    formData.parentRelationship1 === 'father' ||
      formData.parentRelationship1 === 'mother'
      ? DropdownData.parentData
      : formData.parentRelationship1 === 'father in law' ||
        formData.parentRelationship1 === 'mother in law'
      ? DropdownData.parentInLaw
      : DropdownData.parentData
  );
  const [parentType, setParentType] = useState(
    formData.parentRelationship1 === 'father' ||
      formData.parentRelationship1 === 'mother'
      ? 'Parent'
      : formData.parentRelationship1 === 'father in law' ||
        formData.parentRelationship1 === 'mother in law'
      ? 'Parent-in-law'
      : 'Parent'
  );

  const [checkParent, setcheckParent] = useState(
    false || formData['sumInsured'] === 500000
      ? noOfParentsFetched === 2
        ? true
        : false
      : false
  );
  const [checkSingleParent, setCheckSingleParent] = useState(
    false || formData['sumInsured'] === 500000
      ? noOfParentsFetched === 1
        ? true
        : false
      : false
  );
  const riskValue = (empData?.riskRole ? 5 : 3) * parseInt(empData?.ctc);
  const gtlSumInsured = employeeData?.gtlSumInsured
    ? employeeData?.gtlSumInsured
    : 0;
  const firebaseContext = useContext(LoopApiServiceContext);
  const [errorFields, setErrorFields] = useState([]);
  const allowDeletingParent = empData?.canDeleteParent || false;
  let numOfSelectedParent =
    checkParent ||
    empData.canDeleteParent === false ||
    formData['sumInsured'] === 500000
      ? formData.parentName1 && formData.parentName2
        ? 2
        : formData.parentName1 || formData.parentName2
        ? 1
        : 0
      : 0;
  if (
    (checkParent || empData.canDeleteParent === false) &&
    formData.parentName1 &&
    formData.parentName2
  ) {
    numOfSelectedParent = 2;
  } else if (
    (checkParent || empData.canDeleteParent === false) &&
    (formData.parentName1 || formData.parentName2)
  ) {
    numOfSelectedParent = 1;
  }
  const [parentalAdditionCost, setParentalAdditionalCost] = useState(
    numOfSelectedParent ? numOfSelectedParent * 7371 : null
  );

  const handleParentSelection = (event) => {
    if (event.target.checked) {
      setCheckSingleParent(false);
      formData['sumInsured'] = 500000;
      if (checkParentSelection()) {
        setcheckParent(true);
      }
      parentCostCalculation(event, parentalCost);
      if (
        formData['parentName1'] &&
        formData['parentDob1'] &&
        formData['parentRelationship1'] &&
        formData['parentName2'] &&
        formData['parentDob2'] &&
        formData['parentRelationship2']
      ) {
        setcheckParent(true);
      } else {
        toast.error('Please fill all the parent details');
      }
    } else {
      parentCostCalculation(event);
      setcheckParent(false);
      let formFields = { ...formData };
      formFields = { ...formFields, sumInsured: 300000 };
      setFormData(formFields);
    }
  };
  const handleSingleParentSelection = (event) => {
    if (event.target.checked) {
      setcheckParent(false);
      formData['sumInsured'] = 500000;
      if (checkParentSelection()) {
        setCheckSingleParent(true);
      }
      parentCostCalculation(event, singleParentalCost);
      if (
        formData['parentName1'] &&
        formData['parentDob1'] &&
        formData['parentRelationship1']
      ) {
        setCheckSingleParent(true);
      } else {
        toast.error('Please fill single parent details');
      }
    } else {
      parentCostCalculation(event);
      setCheckSingleParent(false);
      let formFields = { ...formData };
      formFields = { ...formFields, sumInsured: 300000 };
      setFormData(formFields);
    }
  };

  const parentCostCalculation = (event, cost = 0) => {
    //event.preventDefault();
    if (event.target.checked) {
      setParentalAdditionalCost(cost);
    } else {
      setParentalAdditionalCost(0);
    }
  };
  const runBeforeRefresh = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const logoutUser = async () => {
    dispatch(cleanup());
    setLoggedInUser(null);
    history.push('/login');
    await logout();
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (enrolmentData?.state === 'succeeded') {
      dispatch(clearEnrolmentState());
      // toast.success(
      //   "Successfully updated enrolment details, you can still update your details till your final enrolment date!",
      //   {
      //     position: toast.POSITION.TOP_CENTER,
      //     theme: "dark",
      //   }
      // );
      if (userData?.data?.userId) {
        dispatch(
          fetchHrUser({
            methodType: 'userId',
            userId: userData.data.userId,
            loginMethod: 'mobile',
          })
        );
      }
      setShowSuccessModal(true);
      setShowSummary(false);
    } else if (enrolmentData?.state === 'failed') {
      dispatch(clearEnrolmentState());
      toast.error('Something went wrong, Please try again later');
      setShowSummary(false);
    }
  }, [dispatch, enrolmentData]);

  const onDeleteParent = () => {
    let data = { ...formData };
    data['parentDob1'] = null;
    data['parentGender1'] = null;
    data['parentName1'] = null;
    data['parentRelationship1'] = null;
    data['parentDob2'] = null;
    data['parentGender2'] = null;
    data['parentName2'] = null;
    data['parentRelationship2'] = null;
    data['sumInsured'] = 300000;
    setFormData(data);
    setParentalAdditionalCost(0);
    setcheckParent(false);
  };

  const onDeleteParent1 = () => {
    let data = { ...formData };
    data['parentDob1'] = null;
    data['parentGender1'] = null;
    data['parentName1'] = null;
    data['parentRelationship1'] = null;
    data['sumInsured'] = checkParent || checkSingleParent ? 500000 : 300000;
    setFormData(data);
    //setParentalAdditionalCost(0);
    //setcheckParent(false);
  };
  const onDeleteParent2 = () => {
    let data = { ...formData };
    data['parentDob2'] = null;
    data['parentGender2'] = null;
    data['parentName2'] = null;
    data['parentRelationship2'] = null;
    data['sumInsured'] = checkParent || checkSingleParent ? 500000 : 300000;
    setFormData(data);
    //setParentalAdditionalCost(0);
    //setcheckParent(false);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', runBeforeRefresh);
    return () => {
      window.removeEventListener('beforeunload', runBeforeRefresh);
    };
  }, []);
  const setFirebaseDate = (date) => {
    const fromDate = new firebaseContext.fromDate(date);
    return fromDate;
  };

  const checkCommonFields = (fieldsData) => {
    let errorFields = [];
    fieldsData.forEach((item) => {
      if (!formData[item]) {
        errorFields.push(item);
      }
    });
    return errorFields;
  };
  const onSubmitParentDetail = () => {
    let formFields = { ...formData };
    formFields = { ...formFields, sumInsured: 500000 };
    setFormData(formFields);
  };
  const handleGPATopUpCalculation = (gpaToUpMultiplier) => {
    let gpaTopUpPremium = (empData?.ctc * gpaToUpMultiplier * 0.15) / 1000;
    let gpaTopUpPremiumGST = gpaTopUpPremium * 0.18;
    gpaTopUpPremium = gpaTopUpPremium + gpaTopUpPremiumGST;
    return Math.round(parseFloat(gpaTopUpPremium)).toFixed(2);
  };

  const validateForm = () => {
    const { relationship, mobile, email, employeeId, name, lastName } =
      formData;
    let errorArr = [];
    let commonFields = ['gender', 'dob'];
    // Validations for Employee
    if (userType === AppConstants.EMPLOYEE) {
      let commonErrArr = checkCommonFields(commonFields);
      if (!employeeId) {
        errorArr.push('employeeId');
      }
      if (!validateName(name)) {
        errorArr.push('name');
      }
      if (!validateName(lastName)) {
        errorArr.push('lastName');
      }
      if (!email && !mobile) {
        // errorArr.push("emailMobile");
        errorArr.push('email');
        errorArr.push('mobile');
      } else {
        if (email && !validateEmail(email)) {
          errorArr.push('email');
        }
        if (mobile && !validateMobile(mobile)) {
          errorArr.push('mobile');
        }
      }
      errorArr.push(...commonErrArr);
      setErrorFields(errorArr);
    } else if (userType === AppConstants.DEPENDENT) {
      // Validation for Dependents
      let commonErrArr = checkCommonFields(commonFields);
      if (!validateName(name)) {
        errorArr.push('name');
      }
      if (!validateName(lastName)) {
        errorArr.push('lastName');
      }
      if (!relationship) {
        errorArr.push('relationship');
      }
      if (email && !validateEmail(email)) {
        errorArr.push('email');
      }
      if (mobile && !validateMobile(mobile)) {
        errorArr.push('mobile');
      }
      errorArr.push(...commonErrArr);
      setErrorFields(errorArr);
    }
    return errorArr;
  };

  const getEmpBody = () => {
    let empBody = employeeData;
    if (formData && formData.name) {
      let body = {};
      Object.keys(formData).forEach((item) => {
        body[item] = formData[item];
        if (
          item === 'dob' ||
          item === 'spouseDob' ||
          item === 'childDob1' ||
          item === 'childDob2' ||
          item === 'childDob3' ||
          item === 'parentDob1' ||
          item === 'parentDob2'
        ) {
          body[item] = body[item]?.seconds;
        }
      });

      let topupAmount = formData['topupAmount']
        ? parseInt(formData['topupAmount'])
        : 0;
      const gpaTopUpPremium = parseInt(
        handleGPATopUpCalculation(gpaMultiplier)
      );
      const gtlTopUpPremium = parseInt(
        handleGTLTopUpCalculation(gtlMultiplier, empData)
      );
      const gmcTopupAmount = topupAmount
        ? parseInt(
            DropdownData.topUpDetails(formData['sumInsured'])?.find(
              (item) => item.name === parseInt(topupAmount)
            )?.annualPremium
          )
        : 0;
      const opdPremium = handleOPDPremiumValues(opdValue);
      const finalAnnualPremium =
        parentalAdditionCost +
        gmcTopupAmount +
        gpaTopUpPremium +
        gtlTopUpPremium +
        opdPremium;
      empBody = {
        ...body,
        userId: empData.userId,
        parentalAdditionCost: parentalAdditionCost,
        gpaSumInsured: riskValue,
        gpaTopupAmount: riskValue + gpaMultiplier * empData.ctc,
        gpaTopupPremium: gpaTopUpPremium,
        gtlTopupAmount: gtlSumInsured + gtlMultiplier * empData.ctc,
        gtlTopupPremium: gtlTopUpPremium,
        opdValue: parseInt(opdValue),
        opdPremium: opdPremium,
        topupAmount: topupAmount,
        gmcTopupAmount: gmcTopupAmount,
        annualPremium: finalAnnualPremium,
        parentName1:
          checkParent || checkSingleParent ? formData['parentName1'] : null,
        parentDob1:
          checkParent || checkSingleParent
            ? formData['parentDob1']?.seconds
            : null,
        parentGender1:
          checkParent || checkSingleParent
            ? formData['parentRelationship1']?.toLowerCase() === 'mother' ||
              formData['parentRelationship1']?.toLowerCase() === 'mother in law'
              ? 'female'
              : 'male'
            : null,
        parentRelationship1:
          checkParent || checkSingleParent
            ? formData['parentRelationship1']
            : null,
        parentName2:
          checkParent || checkSingleParent ? formData['parentName2'] : null,
        parentDob2:
          checkParent || checkSingleParent
            ? formData['parentDob2']?.seconds
            : null,
        parentGender2:
          checkParent || checkSingleParent
            ? formData['parentRelationship2']?.toLowerCase() === 'mother' ||
              formData['parentRelationship2']?.toLowerCase() === 'mother in law'
              ? 'female'
              : 'male'
            : null,
        parentRelationship2:
          checkParent || checkSingleParent
            ? formData['parentRelationship2']
            : null,
        mobileNumber: formData['mobileNumber'],
        emergencyContactMobile: formData['emergencyContactMobile'],
      };
      setEmployeeData(empBody);
    }

    if (!formData.name) {
      toast.error('Invalid name');
      return false;
    }

    if (!formData.gender) {
      toast.error('Invalid gender');
      return false;
    }

    if (
      !formData.dob ||
      !formData.dob.seconds ||
      Number.isNaN(formData.dob.seconds) === true
    ) {
      toast.error('Invalid dob');
      return false;
    }

    const todaysDate = new Date();
    const enrolmentDeadline = new Date(
      empData?.enrolmentDeadline?.seconds * 1000
    );
    if (todaysDate > enrolmentDeadline) {
      toast.error(
        'The Deadline for Enrolment details submission has already passed.'
      );
      return false;
    }

    if (formData.spouseName && !formData.spouseGender) {
      toast.error('Spouse gender is required.');
      return false;
    }

    if (
      formData.spouseName &&
      (!formData.spouseDob ||
        !formData.spouseDob.seconds ||
        Number.isNaN(formData.spouseDob.seconds) === true)
    ) {
      toast.error('Spouse dob is required.');
      return false;
    }

    if (formData.childName1 && !formData.childGender1) {
      toast.error('Child 1 gender is required.');
      return false;
    }

    if (
      formData.childName1 &&
      (!formData.childDob1 ||
        !formData.childDob1.seconds ||
        Number.isNaN(formData.childDob1.seconds) === true)
    ) {
      toast.error('Child 1 dob is required.');
      return false;
    }

    if (formData.childName2 && !formData.childGender2) {
      toast.error('Child 2 gender is required.');
      return false;
    }

    if (
      formData.childName2 &&
      (!formData.childDob2 ||
        !formData.childDob2.seconds ||
        Number.isNaN(formData.childDob2.seconds) === true)
    ) {
      toast.error('Child 2 dob is required.');
      return false;
    }

    if (formData.childName3 && !formData.childGender3) {
      toast.error('Child 3 gender is required.');
      return false;
    }

    if (
      formData.childName3 &&
      (!formData.childDob3 ||
        !formData.childDob3.seconds ||
        Number.isNaN(formData.childDob3.seconds) === true)
    ) {
      toast.error('Child 3 dob is required.');
      return false;
    }

    //Data validation
    let dataError = '';
    if (!empBody.address || empBody.address.length < 3)
      dataError = 'Please Add Address';
    if (!empBody?.bloodGroup)
      dataError = dataError + '\n' + 'Select Blood Group';
    if (
      !formData['emergencyContactName'] ||
      !validateName(formData['emergencyContactName'])
    )
      dataError = dataError + '\n' + 'Enter valid emergency contact name';
    if (
      !formData['emergencyContactAddress'] ||
      !validateName(formData['emergencyContactAddress']?.length > 10)
    )
      dataError = dataError + '\n' + 'Too short emergency contact address';
    if (
      !formData['emergencyContactMobile'] ||
      !validateMobile(formData['emergencyContactMobile']) ||
      formData['emergencyContactMobile']?.length != 10
    )
      dataError =
        dataError + '\n' + 'Enter valid emergency contact mobile number';
    if (
      !formData['mobile'] ||
      !validateMobile(formData['mobile']) ||
      formData['mobile']?.length != 10
    )
      dataError = dataError + '\n' + 'Enter valid mobile number';
    if (formData['mobile'] === formData['emergencyContactMobile'])
      dataError =
        dataError +
        '\n' +
        'Mobile number and emergency contact mobile can not be same.';

    if (
      checkParent &&
      formData.parentRelationship1 &&
      formData.parentRelationship2 &&
      formData.parentRelationship1 === formData.parentRelationship2
    ) {
      toast.error('Same parent relationship cannot be added');
      return false;
    }

    if (checkSingleParent) {
      if (
        (formData.parentDob1 ||
          formData.parentName1 ||
          formData.parentRelationship1 ||
          (formData.parentDob1 && formData.parentDob1.seconds)) &&
        (formData.parentName2 ||
          formData.parentDob2 ||
          formData.parentRelationship2 ||
          (formData.parentDob2 && formData.parentDob2.seconds))
      ) {
        toast.error(
          'You have opted to add one parent. Please delete the details of the other parent before submitting. '
        );
        return false;
      }

      if (
        (!formData.parentDob1 ||
          !formData.parentName1 ||
          !formData.parentRelationship1 ||
          !formData.parentDob1.seconds ||
          Number.isNaN(formData.parentDob1.seconds) === true) &&
        (!formData.parentName2 ||
          !formData.parentDob2 ||
          !formData.parentRelationship2 ||
          !formData.parentDob2.seconds ||
          Number.isNaN(formData.parentDob2.seconds) === true)
      ) {
        toast.error(
          'You have opted to add one parent. Please add details of one parent before submitting.'
        );
        return false;
      }
    }
    if (checkParent) {
      if (
        !formData.parentDob1 ||
        !formData.parentName1 ||
        !formData.parentRelationship1 ||
        !formData.parentName2 ||
        !formData.parentDob2 ||
        !formData.parentRelationship2 ||
        !formData.parentDob1.seconds ||
        Number.isNaN(formData.parentDob1.seconds) === true ||
        !formData.parentDob2.seconds ||
        Number.isNaN(formData.parentDob2.seconds) === true
      ) {
        toast.error(
          'You have opted to add set of two parents. Please add their details before submitting.'
        );
        return false;
      }
    }
    if (!checkParentSelection()) {
      return false;
    }

    let sumInsuredTemp = {};

    if (
      (!empBody.parentName1 ||
        !empBody.parentDob1 ||
        Number.isNaN(empBody.parentDob1) ||
        !empBody.parentGender1) &&
      (!empBody.parentName2 ||
        !empBody.parentDob2 ||
        Number.isNaN(empBody.parentDob2) ||
        !empBody.parentGender2)
    ) {
      sumInsuredTemp = { sumInsured: 300000 };
    }

    if (dataError === undefined || dataError === '')
      return { empData: { ...empBody, ...sumInsuredTemp } };
    else {
      alert(dataError);
      return null;
    }
  };
  const handleRequestForAddition = () => {
    // Validations for Employee
    // const errorArr = validateForm();
    // if (errorArr && errorArr.length > 0) {
    //   return;
    // }
    const empDetail = getEmpBody().empData;
    dispatch(postEnrolmentData({ data: empDetail }));
  };
  const onChangeField = (value, key) => {
    const data = { ...formData };
    data[key] = value;
    if (key === 'relationship') {
      if (value === 'Father') {
        data['gender'] = 'Male';
      } else if (value === 'Mother') {
        data['gender'] = 'Female';
      }
    } else if (
      key === 'dob' ||
      key === 'spouseDob' ||
      key === 'childDob1' ||
      key === 'childDob2' ||
      key === 'childDob3' ||
      key === 'parentDob1' ||
      key === 'parentDob2'
    ) {
      let date = moment(value, 'YYYY-MM-DD').toDate();
      data[key] = setFirebaseDate(date);
    }
    if (
      checkParent &&
      key === 'parentName2' &&
      key === 'parentDob2' &&
      key === 'parentRelationship2'
    ) {
      if (
        data['parentName2'] &&
        data['parentDob2'] &&
        data['parentRelationship2']
      ) {
      }
    }

    setFormData({ ...data });
  };

  const onChangeGPATopup = (value, key) => {
    setGPAMultiplier(value);
  };

  const onChangeGTLTopup = (value, key) => {
    setGTLMultiplier(value);
  };

  const handleParentTypeChange = (value, fieldKey) => {
    if (value === 'Parent') {
      setParentDataOption(DropdownData.parentData);

      const parentName1 = parentData?.[0]?.name || '';
      const parentDob1 = parentData?.[0]?.dob || '';
      const parentGender1 = parentData?.[0]?.gender || '';
      const parentRelationship1 = parentData?.[0]?.relationship || '';

      const parentName2 = parentData?.[1]?.name || '';
      const parentDob2 = parentData?.[1]?.dob || '';
      const parentGender2 = parentData?.[1]?.gender || '';
      const parentRelationship2 = parentData?.[1]?.relationship || '';

      let formValue = { ...formData };
      formValue['parentName1'] = parentName1;
      formValue['parentDob1'] = parentDob1;
      formValue['parentGender1'] = parentGender1;
      formValue['parentRelationship1'] = parentRelationship1;

      formValue['parentName2'] = parentName2;
      formValue['parentGender2'] = parentGender2;
      formValue['parentDob2'] = parentDob2;
      formValue['parentRelationship2'] = parentRelationship2;
      setFormData(formValue);
    } else {
      setParentDataOption(DropdownData.parentInLaw);

      const parentName1 = parentInLawData?.[0]?.name || '';
      const parentDob1 = parentInLawData?.[0]?.dob || '';
      const parentGender1 = parentInLawData?.[0]?.gender || '';
      const parentRelationship1 = parentInLawData?.[0]?.relationship || '';

      const parentName2 = parentInLawData?.[1]?.name || '';
      const parentDob2 = parentInLawData?.[1]?.dob || '';
      const parentGender2 = parentInLawData?.[1]?.gender || '';
      const parentRelationship2 = parentInLawData?.[1]?.relationship || '';

      let formValue = { ...formData };
      formValue['parentName1'] = parentName1;
      formValue['parentDob1'] = parentDob1;
      formValue['parentGender1'] = parentGender1;
      formValue['parentRelationship1'] = parentRelationship1;

      formValue['parentName2'] = parentName2;
      formValue['parentGender2'] = parentGender2;
      formValue['parentDob2'] = parentDob2;
      formValue['parentRelationship2'] = parentRelationship2;
      setFormData(formValue);
    }
    setParentType(value);
  };
  const checkParentInput = () => {
    if (formData?.['parentName1'] && formData?.['parentName2']) {
      let data = { ...formData };
      data['sumInsured'] = 500000;
      setFormData(data);
    } else if (formData?.['parentName1'] || formData?.['parentName2']) {
      let data = { ...formData };
      data['sumInsured'] = 500000;
      setFormData(data);
    } else {
      let data = { ...formData };
      data['sumInsured'] = 300000;
      setFormData(data);
    }
  };
  const handleAddressChecked = () => {
    setCheckAddress(!checkAddress);
    if (!checkAddress) {
      let data = { ...formData };
      data['emergencyContactAddress'] = data['address'];
      setFormData(data);
    } else {
      let data = { ...formData };
      data['emergencyContactAddress'] = '';
      setFormData(data);
    }
  };
  const checkParentSelection = () => {
    const parentNum = parentDependents?.length || 0;
    if (empData.canDeleteParent) {
      if (parentNum === 2) {
        if (!checkParent) {
          return true;
        }
        if (!formData.parentName1 && !formData.parentName2) {
          return true;
        }
        if (
          formData.parentDob1 ||
          formData.parentDob2 ||
          formData.parentName1 ||
          formData.parentName2 ||
          formData.parentRelationship1 ||
          formData.parentRelationship2
        ) {
          if (
            !formData.parentDob1?.seconds ||
            !formData.parentDob2?.seconds ||
            !formData.parentName1 ||
            !formData.parentName2 ||
            !formData.parentRelationship1 ||
            !formData.parentRelationship2
          ) {
            // toast.error('Please make sure to add set of 2 parents or parent in laws');
            // return false;
          }
        }
      } else if (parentNum === 0) {
        if (!checkParent) {
          return true;
        }
        if (!formData.parentName1 && !formData.parentName2) {
          return true;
        }
        //Note: Checking if any of the input field entered
        if (
          !formData.parentDob1?.seconds ||
          !formData.parentDob2?.seconds ||
          formData.parentName1 ||
          formData.parentName2 ||
          formData.parentRelationship1 ||
          formData.parentRelationship2
        ) {
          if (
            !formData.parentDob1 ||
            !formData.parentDob2 ||
            !formData.parentName1 ||
            !formData.parentName2 ||
            !formData.parentRelationship1 ||
            !formData.parentRelationship2
          ) {
            toast.error('Parent 1 and Parent 2 details cannot be empty');
            return false;
          }
        }
      } else if (parentNum === 1) {
        if (!checkParent) {
          return true;
        }
        if (!formData.parentName1 && !formData.parentName2) {
          return true;
        }
        if (
          !formData.parentName1 ||
          !formData.parentDob1 ||
          !formData.parentRelationship1
        ) {
          toast.error('Parent 1 details cannot be empty');
          return false;
        }
        if (
          formData.parentName2 ||
          formData.parentDob2 ||
          formData.parentRelationship2
        ) {
          if (
            !formData.parentName2 ||
            !formData.parentDob2 ||
            !formData.parentRelationship2
          ) {
            toast.error('Parent 2 details cannot be empty');
            return false;
          }
        }
      }
    }
    return true;
  };
  return (
    <>
      {employeeData && <div className='separator_with_employeeData' />}
      <div className={`emp-form-container`}>
        <h4 style={{ marginTop: 12 }}>
          {' '}
          SKF Enrolment Portal – enrol by{' '}
          {new Date(employeeData?.enrolmentDeadline?.seconds * 1000)
            .toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            .replace(/ /g, ' ')}
        </h4>
        <Form noValidate>
          <div className='add-emp-card'>
            <h6>
              Important Details <br />
              <br /> ⚠️ This form is mandatory to properly enroll in your
              health, personal accident, and term life coverage.
              <br />
              <br />
              💡 You must save changes by clicking the submit button at the
              bottom
              <br />
              <br /> 📅 Please complete the below form by{' '}
              {new Date(employeeData?.enrolmentDeadline?.seconds * 1000)
                .toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace(/ /g, '-')}
              <br />
              <br /> ↘ If you require any support or assistance, please email
              info@loophealth.com and cc swati.goswami@skf.com
              <br />
              <br />
              ⚠️ You have the flexibility to submit enrolment multiple times
              using the portal. Yet, please be mindful that only your last
              submission will be treated as the conclusive entry for this year's
              policy enrolment.
            </h6>
          </div>
          <div className='add-emp-card'>
            <div className='form-heading-title'>
              Step 1: Confirm your details
              <br />
              <br />
              <span
                style={{ fontWeight: 'normal', fontSize: 15, marginTop: 14 }}
              >
                Your name must match your government ID. If name change
                required, please email swati.goswami@skf.com and cc
                info@loophealth.com
              </span>
            </div>
            <FormInput
              controlId='input-first-name'
              placeholder={'Enter'}
              onChange={onChangeField}
              value={formData['name']}
              fieldKey={'name'}
              labelName='Employee Name'
              isInvalid={errorFields.includes('name')}
              invalidText='Please enter valid name'
              disabled={false}
            />
            <FormInput
              controlId='input-emp-id'
              placeholder={'Enter'}
              onChange={onChangeField}
              value={formData['employeeId']}
              fieldKey={'employeeId'}
              labelName='Employee ID'
              isInvalid={errorFields.includes('employeeId')}
              invalidText='Enter valid Employee Id'
              disabled={true}
            />
            <FormInput
              controlId='input-date-of-birth'
              placeholder={'Enter'}
              onChange={onChangeField}
              value={convertToDate(formData['dob'])}
              fieldKey={'dob'}
              labelName='Employee DOB'
              type='date'
              textTransform='uppercase'
              disabled={false}
              max={
                userType === AppConstants.EMPLOYEE
                  ? moment().subtract(18, 'years').format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD')
              }
              isInvalid={errorFields.includes('dob')}
              invalidText='Please select valid DOB'
            />

            <div className='input-container'>
              {/*
                <FormInput
                    controlId='input-emp-id'
                    labelName='Gender'
                    fieldKey='gender'
                    onChange={onChangeField}
                    value={formData['gender']}
                isInvalid={errorFields.includes('employeeId')}
                    disabled={false}
                />
                */}

              <TagOption
                controlId='input-tag'
                labelName='Gender'
                fieldKey='gender'
                value={formData['gender']}
                option={DropdownData.genderData}
                onChange={onChangeField}
                isCapFirstChar={true}
              />
              <FormInput
                controlId='input-first-name'
                placeholder={'Enter'}
                onChange={onChangeField}
                maxLength={10}
                value={formData['mobile']}
                fieldKey={'mobile'}
                labelName='Mobile Number*'
                isInvalid={errorFields.includes('mobile')}
                invalidText='Please enter valid mobile number'
              />

              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Blood Group*'
                value={formData['bloodGroup']}
                fieldKey='bloodGroup'
                options={DropdownData.bloodGroup}
                displayKey='name'
                onChange={onChangeField}
                isInvalid={errorFields.includes('bloodGroup')}
                invalidText='Please select blood group'
              />
            </div>
            <FormInput
              controlId='input-first-name'
              placeholder={'Enter'}
              onChange={onChangeField}
              value={formData['address']}
              fieldKey={'address'}
              labelName='Home Address*'
              isAddress={true}
              isInvalid={errorFields.includes('address')}
              invalidText='Please enter valid home address'
            />
          </div>
          <div className='add-emp-card'>
            <div className='form-heading-title'>
              Step 2: Provide your emergency contact information
            </div>

            <FormInput
              controlId='input-first-name'
              placeholder={'Enter'}
              onChange={onChangeField}
              value={formData['emergencyContactName']}
              fieldKey={'emergencyContactName'}
              labelName='Name of emergency contact*'
              isInvalid={errorFields.includes('emergencyContactName')}
              invalidText='Please enter valid name'
            />

            <div className='App'>
              <div className='topping'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkAddress}
                      onChange={handleAddressChecked}
                      color={'primary'}
                    />
                  }
                  label='Check this box if emergency address is same as your own home
                  address.'
                />
              </div>

              <FormInput
                controlId='input-first-name'
                placeholder={'Enter'}
                disabled={checkAddress}
                onChange={onChangeField}
                value={formData['emergencyContactAddress']}
                fieldKey={'emergencyContactAddress'}
                labelName='Emergency Contact Address*'
                isAddress={true}
                isInvalid={errorFields.includes('emergencyContactAddress')}
                invalidText='Please enter valid contact address'
              />
            </div>

            <FormInput
              controlId='input-first-name'
              placeholder={'Enter'}
              onChange={onChangeField}
              value={formData['emergencyContactMobile']}
              fieldKey={'emergencyContactMobile'}
              labelName='Emergency Contact Number*'
              isInvalid={errorFields.includes('emergencyContactMobile')}
              invalidText='Please enter valid contact phone no.'
              maxLength='10'
            />
          </div>
          <div className='add-emp-card step-3-container'>
            <div className='form-heading-title'>
              Step 3: Enroll/verify your immediate family (1+4) for Mediclaim
              <br />
              <br />
              <span
                style={{ fontWeight: 'normal', fontSize: 15, marginTop: 14 }}
              >
                You have a 1+4 policy with 3 lacs of Family Floater Sum Insured
                (SI) that covers you, your spouse, and 3 children up to age 25
                years old. You may add or edit their information below. You will
                also be able to add parents or increase your total SI in the
                next steps.
                <br />
                <br />
                You may add a live-in partner to your insurance policy. To do
                so, simply add them by adding their details in the “Spouse /
                Partner” field. Once enrolled, you cannot change your live-in
                partner during the policy period (2024).
                <br />
                <br />
                To be more inclusive to all employees we are allowing same sex
                partners to be added to the insurance policy this year onwards.
                The same can be added under the Spouse / Partner field.
              </span>
            </div>
            <div className='input-container'>
              <FormInput
                controlId='input-first-name'
                placeholder={'Enter'}
                value={formData['name']}
                fieldKey={'name'}
                labelName='Employee Name'
                disabled={true}
                isInvalid={errorFields.includes('name')}
              />
              <FormInput
                controlId='input-date-of-birth'
                placeholder={'Enter'}
                value={convertToDate(formData['dob'])}
                fieldKey={'dob'}
                labelName='DOB'
                type='date'
                textTransform='uppercase'
                max={
                  userType === AppConstants.EMPLOYEE
                    ? moment().subtract(26, 'years').format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')
                }
                isInvalid={errorFields.includes('dob')}
                invalidText='Please select valid DOB'
                disabled={true}
              />
              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Gender'
                value={formData['gender']}
                fieldKey='gender'
                textCapitalize={true}
                options={DropdownData.genderData}
                displayKey='name'
                isInvalid={errorFields.includes('gender')}
                invalidText='Please select gender'
                disabled={true}
              />
            </div>

            <div className='input-container'>
              <FormInput
                controlId='input-first-name'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={formData['spouseName']}
                fieldKey={'spouseName'}
                labelName='Spouse / Partner Name '
                isInvalid={errorFields.includes('spouseName')}
                invalidText='Please enter valid name'
              />
              <FormInput
                controlId='input-date-of-birth'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={convertToDate(formData['spouseDob'])}
                fieldKey={'spouseDob'}
                labelName='DOB'
                type='date'
                textTransform='uppercase'
                max={moment().format('YYYY-MM-DD')}
                isInvalid={errorFields.includes('spouseDob')}
                invalidText='Please select valid DOB'
              />

              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Gender'
                value={formData['spouseGender']}
                fieldKey='spouseGender'
                options={DropdownData.genderData}
                textCapitalize={true}
                displayKey='name'
                onChange={onChangeField}
                isInvalid={errorFields.includes('spouseGender')}
                invalidText='Please select gender'
              />
            </div>

            <div className='input-container'>
              <FormInput
                controlId='input-first-name'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={formData['childName1']}
                fieldKey={'childName1'}
                labelName='Child 1 Name'
                isInvalid={errorFields.includes('childName1')}
                invalidText='Please enter valid name'
              />
              <FormInput
                controlId='input-date-of-birth'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={convertToDate(formData['childDob1'])}
                fieldKey={'childDob1'}
                labelName='DOB'
                type='date'
                textTransform='uppercase'
                max={moment().format('YYYY-MM-DD')}
                min={moment().subtract(26, 'years').format('YYYY-MM-DD')}
                isInvalid={errorFields.includes('childDob1')}
                invalidText='Please select valid DOB'
              />
              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Gender'
                textCapitalize={true}
                value={formData['childGender1']}
                fieldKey='childGender1'
                options={DropdownData.genderData}
                displayKey='name'
                onChange={onChangeField}
                isInvalid={errorFields.includes('childGender1')}
                invalidText='Please select gender'
              />
            </div>

            <div className='input-container'>
              <FormInput
                controlId='input-first-name'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={formData['childName2']}
                fieldKey={'childName2'}
                labelName='Child 2 Name'
                isInvalid={errorFields.includes('childName2')}
                invalidText='Please enter valid name'
              />
              <FormInput
                controlId='input-date-of-birth'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={convertToDate(formData['childDob2'])}
                fieldKey={'childDob2'}
                labelName='DOB'
                type='date'
                textTransform='uppercase'
                max={moment().format('YYYY-MM-DD')}
                min={moment().subtract(26, 'years').format('YYYY-MM-DD')}
                isInvalid={errorFields.includes('childDob2')}
                invalidText='Please select valid DOB'
              />
              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Gender'
                textCapitalize={true}
                value={formData['childGender2']}
                fieldKey='childGender2'
                options={DropdownData.genderData}
                displayKey='name'
                onChange={onChangeField}
                isInvalid={errorFields.includes('childGender2')}
                invalidText='Please select gender'
              />
            </div>

            <div className='input-container'>
              <FormInput
                controlId='input-first-name'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={formData['childName3']}
                fieldKey={'childName3'}
                labelName='Child 3 Name'
                isInvalid={errorFields.includes('childName3')}
                invalidText='Please enter valid name'
              />
              <FormInput
                controlId='input-date-of-birth'
                placeholder={'Enter'}
                onChange={onChangeField}
                value={convertToDate(formData['childDob3'])}
                fieldKey={'childDob3'}
                labelName='DOB'
                type='date'
                textTransform='uppercase'
                max={moment().format('YYYY-MM-DD')}
                min={moment().subtract(26, 'years').format('YYYY-MM-DD')}
                isInvalid={errorFields.includes('childDob3')}
                invalidText='Please select valid DOB'
              />
              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Gender'
                value={formData['childGender3']}
                textCapitalize={true}
                fieldKey='childGender3'
                options={DropdownData.genderData}
                displayKey='name'
                onChange={onChangeField}
                isInvalid={errorFields.includes('childGender3')}
                invalidText='Please select gender'
              />
            </div>
          </div>

          <div className='add-emp-card'>
            <div className='form-heading-title'>
              Step 4: Enroll/verify your parents (1+6) for Mediclaim
              <br />
              <br />
              <span
                style={{ fontWeight: 'normal', fontSize: 15, marginTop: 14 }}
              >
                You have the option to add your parents to the Mediclaim policy.
                SKF will bear 50% of the cost and the other 50% will be borne by
                you. The amount displayed below is your employee contribution
                and will be deducted from your salary in two installments. By
                enrolling a parent, your Family Floater Sum Insured will
                increase from 3 Lacs to 5 Lacs of coverage.
              </span>
              <br />
              <br />
              <span
                style={{ fontWeight: 'normal', fontSize: 15, marginTop: 14 }}
              >
                You may only add a set of two parents or two in-laws. If one of
                your parents is deceased, please email swati.goswami@skf.com and
                cc info@loophealth.com
              </span>
            </div>

            <TagOption
              controlId='input-tag'
              labelName={null}
              fieldKey='parentType'
              value={parentType}
              option={DropdownData.parentTypeData}
              onChange={handleParentTypeChange}
              isInvalid={errorFields.includes('parentType')}
            />

            <div>
              <div className='inpu-box-step4'>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={checkParent || formData?.canDeleteParent === false}
                        checked={checkParent}
                        onChange={(event) =>
                          handleParentSelection(event, 'parent')
                        }
                        // disabled={formData?.canDeleteParent === false}
                        color={'primary'}
                      />
                    }
                    label='By checking this box, I opt to enroll my set of 2 parents or 2 in-laws with details as below.'
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={checkSingleParent || formData?.canDeleteParent === false}
                        checked={checkSingleParent}
                        onChange={(event) =>
                          handleSingleParentSelection(event, 'parent')
                        }
                        // disabled={formData?.canDeleteParent === false}
                        color={'primary'}
                      />
                    }
                    label='By checking this box, I confirm that one of my parents is deceased, 
                                        and I choose to enroll only one parent in the policy. Alternatively, if both parents are alive, I am interested in enrolling only one parent in the policy'
                  />
                </div>

                <div className='row'>
                  <div className='col-md-5'>
                    <FormInput
                      controlId='input-first-name'
                      placeholder={'Enter'}
                      onChange={onChangeField}
                      value={formData['parentName1']}
                      fieldKey={'parentName1'}
                      labelName='Parent 1 Name'
                      onBlur={checkParentInput}
                      isInvalid={errorFields.includes('parentName1')}
                      invalidText='Please enter valid name'
                    />
                  </div>
                  <div className='col-md-5'>
                    <FormInput
                      controlId='input-date-of-birth'
                      placeholder={'Enter'}
                      onChange={onChangeField}
                      value={convertToDate(formData['parentDob1'])}
                      fieldKey={'parentDob1'}
                      labelName='Parent 1 DOB'
                      type='date'
                      textTransform='uppercase'
                      max={moment().format('YYYY-MM-DD')}
                      isInvalid={errorFields.includes('parentDob1')}
                      invalidText='Please select valid DOB'
                    />
                  </div>
                </div>
                <div className='row'>
                  {/* <div className="col-md-5">
                                        <Dropdown
                                            controlId="input-dropdown"
                                            placeholder="Select"
                                            labelName="Parent 1 Gender"
                                            value={formData['parentGender1']}
                                            fieldKey="parentGender1"
                                            options={DropdownData.genderData}
                                            displayKey="name"
                                            onChange={onChangeField}
                                            isInvalid={errorFields.includes('parentGender1')}
                                            invalidText="Please select gender"
                                            disabled={formData?.canDeleteParent === false}
                                        />
                                    </div> */}
                  <div className='col-md-5'>
                    {' '}
                    <Dropdown
                      controlId='input-dropdown'
                      placeholder='Select'
                      labelName='Parent 1 Relationship'
                      textCapitalize={true}
                      value={formData['parentRelationship1']}
                      fieldKey='parentRelationship1'
                      options={parentDataOption}
                      displayKey='name'
                      onChange={onChangeField}
                      isInvalid={errorFields.includes('parentRelationship1')}
                      invalidText='Please select relationship'
                    />
                  </div>
                  <div className='delete-container col-md-1'>
                    {(allowDeletingParent || checkSingleParent) && (
                      <IconButton
                        aria-label='delete'
                        onClick={() => onDeleteParent1()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='inpu-box-step4'>
              <div className='row'>
                <div className='col-md-5'>
                  <FormInput
                    controlId='input-first-name'
                    placeholder={'Enter'}
                    onChange={onChangeField}
                    value={formData['parentName2']}
                    fieldKey={'parentName2'}
                    labelName='Parent 2 Name'
                    isInvalid={errorFields.includes('parentName2')}
                    invalidText='Please enter valid name'
                    // disabled={
                    //   empData.canDeleteParent === false &&
                    //   parentDependents?.length === 1
                    // }
                  />
                </div>
                <div className='col-md-5'>
                  <FormInput
                    controlId='input-date-of-birth'
                    placeholder={'Enter'}
                    onChange={onChangeField}
                    value={convertToDate(formData['parentDob2'])}
                    fieldKey={'parentDob2'}
                    onBlur={checkParentInput}
                    labelName='Parent 2 DOB'
                    type='date'
                    textTransform='uppercase'
                    max={moment().format('YYYY-MM-DD')}
                    isInvalid={errorFields.includes('parentDob2')}
                    invalidText='Please select valid DOB'
                    // disabled={
                    //   empData.canDeleteParent === false &&
                    //   parentDependents?.length === 1
                    // }
                  />
                </div>
              </div>
              <div className='row'>
                {/* <div className="col-md-5">
                                    <Dropdown
                                        controlId="input-dropdown"
                                        placeholder="Select"
                                        labelName="Parent 2 Gender"
                                        value={formData['parentGender2']}
                                        fieldKey="parentGender2"
                                        options={DropdownData.genderData}
                                        displayKey="name"
                                        onChange={onChangeField}
                                        isInvalid={errorFields.includes('parentGender2')}
                                        invalidText="Please select gender"
                                        disabled={formData?.canDeleteParent === false}
                                    />
                                </div> */}
                <div className='col-md-5'>
                  <Dropdown
                    controlId='input-dropdown'
                    placeholder='Select'
                    labelName='Parent 2 Relationship'
                    value={formData['parentRelationship2']}
                    fieldKey='parentRelationship2'
                    options={parentDataOption}
                    textCapitalize={true}
                    displayKey='name'
                    onChange={onChangeField}
                    isInvalid={errorFields.includes('parentRelationship2')}
                    invalidText='Please select gender'
                    // disabled={
                    //   empData.canDeleteParent === false &&
                    //   parentDependents?.length === 1
                    // }
                  />
                </div>
                <div className='delete-container col-md-1'>
                  {(allowDeletingParent || checkSingleParent) && (
                    <IconButton
                      aria-label='delete'
                      onClick={() => onDeleteParent2()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>

            <div className='row'>
              {/* {formData?.canDeleteParent === false ? } */}
              <div className='col-md-10'>
                <div className='form-heading-title'>
                  {(checkParent > 0 || checkSingleParent > 0) && (
                    <span
                      style={{
                        fontWeight: 'normal',
                        fontSize: 15,
                        marginTop: 14,
                      }}
                    >
                      {`You have selected ${
                        checkSingleParent ? 1 : checkParent ? 2 : 0
                      } parent to add to Mediclaim.`}{' '}
                      <br />
                    </span>
                  )}
                  {checkParent || checkSingleParent
                    ? `→ Your payable parental premium is ₹ ${parentalAdditionCost?.toLocaleString(
                        'en-In'
                      )} (incl GST)`
                    : null}
                </div>
              </div>
              {/* {numOfSelectedParent > 0 && formData?.canDeleteParent && (
                                <div className="delete-container col-md-1">
                                    <IconButton aria-label="delete" onClick={() => onDeleteParent()}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            )} */}
            </div>
          </div>
          <div>
            <div className='add-emp-card'>
              <div className='form-coverage-title'>
                Step 5: Purchase the additional coverage for your Mediclaim
                Policy
                <br />
                <br />
                <span
                  style={{ fontWeight: 'normal', fontSize: 15, marginTop: 14 }}
                >
                  You may opt for an additional “top-up” coverage. The total
                  premium will be payable by you in whole.
                </span>
              </div>
              <div>{`Your current Mediclaim family floater Sum Insured is: ₹ ${formData[
                'sumInsured'
              ]?.toLocaleString('en-In')}`}</div>

              <br />

              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Select the amount of coverage you would like to add'
                value={formData.topupAmount}
                fieldKey='topupAmount'
                options={DropdownData.topUpDetails(formData['sumInsured'])}
                displayKey='name'
                onChange={onChangeField}
                isInvalid={errorFields.includes('topupAmount')}
                invalidText='Please select coverage amount'
              />

              {/*Your current Mediclaim Sum Insured is: 3L */}
              <div>
                {`Your current Mediclaim Sum Insured is: ₹ ${formData.sumInsured?.toLocaleString(
                  'en-In'
                )}`}
              </div>

              {/* Your top-up coverage selected is: 5L */}
              <div>
                {`Your top-up coverage selected is: ₹ ${
                  formData['topupAmount'] && formData['sumInsured']
                    ? parseInt(formData['topupAmount'])?.toLocaleString('en-In')
                    : 'NA'
                }`}
              </div>

              {/* Your final Mediclaim Sum Insured is : 8L */}
              <div>
                {`Your final Mediclaim Sum Insured is : ₹ ${(
                  parseInt(formData.sumInsured) + parseInt(formData.topupAmount)
                )?.toLocaleString('en-In')}`}
              </div>
              <br />
              {/* → The cost for Mediclaim top-up is: ₹ 3489 (incl GST) */}
              <div className='form-heading-title'>
                {`→ The cost for Mediclaim top-up is: ₹
                ${
                  DropdownData.topUpDetails(formData['sumInsured'])
                    ?.find(
                      (item) => item.name === parseInt(formData.topupAmount)
                    )
                    ?.annualPremium?.toLocaleString('en-In') || 'NA'
                }  (Incl GST) `}
              </div>
            </div>
            <div className='add-emp-card'>
              <div className='form-heading-title'>
                Step 6: Top up your Group Personal Accident (GPA) Policy
              </div>
              <div>
                For SKF employees, GPA Sum Insured amount is 3X your CTC
              </div>
              <br />
              <div>
                {`Your CTC that is your gross compensation less 
                gratuity for insurance purposes is: ₹ ${parseInt(
                  empData?.['ctc']
                )?.toLocaleString('en-In')}`}
              </div>
              <br />
              <div>{`Your base GPA Sum Insured is: ₹ ${riskValue?.toLocaleString(
                'en-In'
              )}`}</div>
              <div>
                This amount would cover the cost of expenses borne by you in the
                case of an accident.
                <br />
                In case of accidental death, the sum insured would be paid to
                your beneficiary.
              </div>
              <br />

              {!empData?.riskRole && (
                <div>
                  <div>
                    {' '}
                    You can increase your GPA coverage by an additional 2X so
                    that total coverage would be 5X your CTC.
                  </div>
                  <div>
                    The top-up premium is affordable and calculated as: (Total
                    top-up amount) / 1000 x 0.15.
                  </div>
                  <div>
                    Choose your desired top-up amount below and view the cost of
                    your selection and total GPA Sum Insured (SI) below{' '}
                  </div>
                  <br />
                  <Dropdown
                    controlId='input-dropdown'
                    placeholder='Select'
                    labelName='Select the top-up multiplier'
                    value={gpaMultiplier}
                    options={DropdownData.topUpData}
                    displayKey='name'
                    onChange={onChangeGPATopup}
                    isInvalid={errorFields.includes('topupAmount')}
                    invalidText='Please select coverage amount'
                  />

                  <div>{`Your base GPA Sum Insured is: ₹ ${riskValue?.toLocaleString(
                    'en-In'
                  )}`}</div>
                  <div>{`You’ve opted for additional GPA top-up coverage of: ₹ ${(
                    gpaMultiplier * empData.ctc
                  )?.toLocaleString('en-In')}`}</div>
                  <div>
                    {`Your total GPA coverage: ₹ ${(
                      riskValue +
                      gpaMultiplier * empData.ctc
                    )?.toLocaleString('en-In')}`}
                  </div>
                  <br />
                  <div className='form-heading-title'>{`→ Premium for your GPA top-up: ₹ ${handleGPATopUpCalculation(
                    gpaMultiplier
                  )?.toLocaleString('en-In')} (Incl of GST)`}</div>
                </div>
              )}
            </div>

            <div className='add-emp-card'>
              <div className='form-heading-title'>
                Step 7: Your Group Term Life Policy
              </div>
              <div>
                Group Term Life coverage is a pure term insurance policy
                provided to corporate and covers all employees under one master
                policy. The policy provides lump sum payment to the insured’s
                nominated beneficiary in the event of death due to natural
                causes, illness or accident.
              </div>
              <br />
              <div>
                {`All SKF employees get GTL Sum Insured benefit of 2X CTC, 
                                except for employees from Brand Protection team who get GTL Sum Insured benefit of 4X CTC.`}
              </div>
              <br />
              <div>
                {`Your CTC that is your gross compensation less gratuity for insurance purposes is ₹ ${
                  employeeData?.ctc?.toLocaleString('en-In') || 'NA'
                }`}
              </div>
              <br />
              <div>
                {`Your GTL Sum Insured is ₹ ${
                  employeeData?.gtlSumInsured?.toLocaleString('en-In') || 'NA'
                }`}
              </div>
              <br />
              {!empData?.riskRole && (
                <div>
                  <span style={{ fontWeight: 'bold' }}>NEW FOR THIS YEAR </span>
                  <div>
                    {' '}
                    You can increase your GTL coverage by an additional 1X of
                    your CTC.
                  </div>
                  <div>
                    The top-up premium is affordable and calculated as: (Total
                    top-up amount) / 1000 x{' '}
                    {caluclateAgeWiseRatePerMil(empData?.dob, empData?.gender)}
                  </div>
                  <div>
                    Choose your desired top-up amount below and view the cost of
                    your selection and total GTL Sum Insured (SI) below{' '}
                  </div>
                  <br />
                  <Dropdown
                    controlId='input-dropdown'
                    placeholder='Select'
                    labelName='Select the top-up multiplier'
                    value={gtlMultiplier}
                    options={DropdownData.gtlTopUpData}
                    displayKey='name'
                    onChange={onChangeGTLTopup}
                    isInvalid={errorFields.includes('topupAmount')}
                    invalidText='Please select coverage amount'
                  />

                  <div>{`Your base GTL Sum Insured is: ₹ ${gtlSumInsured.toLocaleString(
                    'en-In'
                  )}`}</div>
                  <div>{`You’ve opted for additional GTL top-up coverage of: ₹ ${(
                    gtlMultiplier * empData.ctc
                  )?.toLocaleString('en-In')}`}</div>
                  <div>
                    {`Your total GTL coverage: ₹ ${(
                      gtlSumInsured +
                      gtlMultiplier * empData.ctc
                    )?.toLocaleString('en-In')}`}
                  </div>
                  <br />
                  <div className='form-heading-title'>{`→ Premium for your GTL top-up: ₹ ${handleGTLTopUpCalculation(
                    gtlMultiplier,
                    empData
                  )?.toLocaleString('en-In')} (Incl of GST)`}</div>
                </div>
              )}
            </div>

            <div className='add-emp-card'>
              <div className='form-heading-title'>Step 8: OPD Policy</div>
              <span style={{ fontWeight: 'bold' }}>NEW FOR THIS YEAR </span>
              <div>
                The OPD cover refers to the cover plan, along with your health
                insurance policy, that covers all the OPD charges or outpatient
                treatment charges. This cover ensures that the policyholder gets
                financial coverage against all the treatment and consultation
                charges related to the OPD without hospitalization.
              </div>
              <br />
              <div>
                <b>OPD Policy Covers:</b>
                <Opdtables header={coverageHeader} rowData={coverageRowData} />
              </div>
              <br />
              <div>
                <b>Premium for OPD Policy:</b>
                <Opdtables
                  header={slabSelectionHeader}
                  rowData={slabSelectionRowData}
                />
              </div>
              <br />
              <Dropdown
                controlId='input-dropdown'
                placeholder='Select'
                labelName='Select your preferred OPD Sum Insured'
                value={opdValue}
                options={DropdownData.opdPolicy}
                displayKey='name'
                onChange={onChangeOPDValue}
                isInvalid={errorFields.includes('opdValue')}
                invalidText='Please select the value from dropdown'
              />
              <div>{`Your have selected OPD Sum Insured as: ₹ ${opdValue.toLocaleString(
                'en-In'
              )}`}</div>
              <br />
              <div>{`Premium for your OPD Policy: ₹ ${handleOPDPremiumValues(
                opdValue
              ).toLocaleString('en-In')} (Incl of GST)`}</div>
            </div>
          </div>
          <div className='separator' />
          <div
            className={`hr-request-button`}
            onClick={() => {
              if (getEmpBody()) setShowSummary(true);
            }}
          >
            Submit your enrollment
          </div>
          <div className='separator' />
        </Form>
        <SuccessModal
          showModal={showSuccessModal}
          onHandleSubmit={logoutUser}
        />
        {showSummary && (
          <SummaryData
            showModal={showSummary}
            setShowModal={setShowSummary}
            empData={empData}
            empBody={employeeBody}
            numOfSelectedParent={numOfSelectedParent}
            loading={enrolmentData?.state === 'loading'}
            gpaBaseSumInsured={riskValue}
            gpaTopUp={gpaMultiplier * empData.ctc}
            enrolmentDeadline={new Date(
              employeeData?.enrolmentDeadline?.seconds * 1000
            )
              .toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              .replace(/ /g, '-')}
            gpaFinalSumInsuredCoverage={riskValue + gpaMultiplier * empData.ctc}
            parentalAdditionCost={parentalAdditionCost}
            gtlSumInsured={employeeData?.gtlSumInsured}
            gtlTopUp={gtlMultiplier * empData.ctc}
            gtlFinalSumInsuredCoverage={
              gtlSumInsured + gtlMultiplier * empData.ctc
            }
            onHandleSubmit={handleRequestForAddition}
          />
        )}
      </div>
    </>
  );
};
