import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./hoc/PrivateRoute";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";

import Login from "./screens/Login";
import RegisterUsers from "./screens/RegisterUsers";
import RegisterSingleUser from "./screens/RegisterSingleUser";

import EditUserScreen from "./screens/EditUserScreen";
import EditDependentScreen from "./screens/EditDependentScreen";
import AddDependentScreen from "./screens/AddDependentsScreen";

import { AuthProvider } from "./contexts/AuthContext";
import RedirectAuthRoute from "./hoc/RedirectAuthRoute";
import ViewEmployeeData from "./screens/ViewEmployeeData";
import EnterOtp from "./screens/enterOtp/EnterOtp";
import EnterAuth from "./screens/enterVerify/EnterAuth";
import LoopApiServiceContext from "./contexts/LoopApiServiceContext";
import LoopApiService from "./services/network/LoopApiService";
import ViewEmployeeDetail from "./screens/viewEmployee/ViewEmployeeDetail";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LoopApiServiceContext.Provider value={LoopApiService}>
            <AuthProvider>
              <Switch>
                <RedirectAuthRoute path="/login" component={Login} />
                <RedirectAuthRoute path="/enter-otp" component={EnterOtp} />
                <RedirectAuthRoute path="/enter-id" component={EnterAuth} />
                <PrivateRoute
                  path="/register-users/:companyid"
                  component={RegisterUsers}
                />
                <PrivateRoute
                  path="/register-single-user"
                  component={ViewEmployeeDetail}
                />
                <PrivateRoute path="/edit/:id" component={EditUserScreen} />
                <PrivateRoute
                  path="/edit-dependent/:id"
                  component={EditDependentScreen}
                />
                <PrivateRoute
                  path="/add-dependent/:userid/:mobileno/:firstname/:lastname"
                  component={AddDependentScreen}
                />
                <PrivateRoute
                  path="/view-employee"
                  component={ViewEmployeeData}
                />
                <PrivateRoute exact path="/" component={ViewEmployeeDetail} />
              </Switch>
            </AuthProvider>
            <ToastContainer position="top-center" />
          </LoopApiServiceContext.Provider>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
