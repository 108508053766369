import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AppConstants } from '../../common/AppConstants';
import LoopApiService from '../../services/network/LoopApiService';
import { addDependentList } from './companySlice';

const apiState = {
  data: null,
  state: 'idle',
  error: '',
};

export const updateEmployeeRequest = createAsyncThunk(
  'hrRequest/updateEmployeeRequest',
  async ({ data, existingData }) => {
    try {
      const result = await LoopApiService.addEmpHrRequest(data);
      return {
        data: result,
      };
    } catch (error) {
      console.log('update error:', error);
    }
  }
);

export const deleteEmployeeRequest = createAsyncThunk(
  'hrRequest/deleteEmployeeRequest',
  async ({ data }) => {
    try {
      const result = await LoopApiService.addEmpHrRequest(data);
      return {
        data: result,
      };
    } catch (error) {
      console.log('delete emp data:', error);
    }
  }
);

//Note: creates add request in hrRequest collection.
export const addEmployeeRequest = createAsyncThunk(
  'hrRequest/addEmployeeRequest',
  async ({ data }) => {
    try {
      const result = await LoopApiService.addEmpHrRequest(data);
      return {
        userdata: result.userdata,
      };
    } catch (error) {
      console.log('add req:', error);
    }
  }
);
//Note: Creates a dependent request in existing employee.
export const addDepInEmp = createAsyncThunk(
  'hrRequest/addDepInEmp',
  async ({ data }, { dispatch }) => {
    try {
      const result = await LoopApiService.addDependentInEmp(data);
      dispatch(addDependentList({ data: result }));
      return {
        data: result,
      };
    } catch (error) {
      console.log('add dep req:', error);
    }
  }
);
//Fetch the pending list of employees
export const fetchHrRequestList = createAsyncThunk(
  'hrRequest/fetchHrRequestList',
  async ({ companyId }) => {
    const result = await LoopApiService.getHrRequestData(companyId);
    let pendingData = {};

    if (result && result.length) {
      result.forEach((item) => {
        pendingData[item.userId] = item;
      });
    }
    return {
      data: result,
      pendingData: pendingData,
    };
  }
);

export const postEnrolmentData = createAsyncThunk(
  'hrRequest/postEnrolmentData',
  async ({ data }) => {
    try {
      const result = await LoopApiService.postEnrolmentData(data);
      return {
        data: result,
      };
    } catch (error) {
      throw Error;
      console.log('update emp:', error);
    }
  }
);

export const addDepdendentHrReq = createAsyncThunk(
  'hrRequest/addDepdendentHrReq',
  async ({ data, docRef }) => {
    try {
      const result = await LoopApiService.addDepdendentHrReq(docRef, data);
      return {
        data: result,
      };
    } catch (error) {
      console.log('update emp:', error);
    }
  }
);

export const getEmployeeDataReq = createAsyncThunk(
  'hrRequest/getEmployeeDataReq',
  async ({ docRef }) => {
    try {
      const result = await LoopApiService.getRequestedEmp(docRef);
      return {
        data: result,
      };
    } catch (error) {
      console.log('emp get data err:', error);
    }
  }
);

const hrRequestSlice = createSlice({
  name: 'hrRequest',
  initialState: {
    addHrRequest: {
      ...apiState,
    },
    employeePendingList: {
      ...apiState,
    },
    employeePendingMapList: null,
    updateHrRequest: {
      ...apiState,
    },
    deleteHrRequest: {
      ...apiState,
    },
    employeeData: null,
    addDependentReq: {
      ...apiState,
    },
    empDataReq: {
      ...apiState,
    },
    addDepInEmp: {
      ...apiState,
    },
    addEnrolmentData: {
      ...apiState,
    },
  },
  reducers: {
    setEmployeeData(state, action) {
      state.employeeData = action.payload.data;
    },
    clearEnrolmentState(state, action) {
      state.addEnrolmentData.state = 'idle';
    },
    clearHrReqState(state, action) {
      let initState = { ...apiState };
      switch (action.payload.data) {
        case AppConstants.DELETE:
          state.deleteHrRequest = initState;
          break;
        case AppConstants.UPDATE:
          state.updateHrRequest = initState;
          break;
        case AppConstants.ADD:
          state.addHrRequest = initState;
          break;
        case AppConstants.ADD_DEPENDENT:
          state.addDependentReq = initState;
          state.addDepInEmp = initState;
          break;
        case AppConstants.EMP_HR_REQUEST_DATA:
          state.empDataReq = initState;
          break;
        default:
          break;
      }
    },
  },
  extraReducers: {
    [addEmployeeRequest.pending]: (state, action) => {
      state.addHrRequest.state = 'loading';
      state.addHrRequest.error = '';
    },
    [addEmployeeRequest.fulfilled]: (state, action) => {
      state.addHrRequest.data = action.payload.userdata;
      state.addHrRequest.state = 'succeeded';
    },
    [addEmployeeRequest.rejected]: (state, action) => {
      state.addHrRequest.state = 'failed';
      state.addHrRequest.error = action.error.message;
    },
    [fetchHrRequestList.pending]: (state, action) => {
      state.employeePendingList.state = 'loading';
      state.employeePendingList.error = '';
    },
    [fetchHrRequestList.fulfilled]: (state, action) => {
      state.employeePendingList.state = 'succeeded';
      state.employeePendingList.data = action.payload.data || [];
      state.employeePendingMapList = action.payload.pendingData || null;
    },
    [fetchHrRequestList.rejected]: (state, action) => {
      state.employeePendingList.state = 'failed';
      state.employeePendingList.error = action.error.message;
    },
    [updateEmployeeRequest.pending]: (state, action) => {
      state.updateHrRequest.state = 'loading';
      state.updateHrRequest.error = '';
    },
    [updateEmployeeRequest.fulfilled]: (state, action) => {
      state.updateHrRequest.data = action.payload.data;
      state.updateHrRequest.state = 'succeeded';
      if (action.payload.data.patientType !== 'dependent') {
        state.employeeData = { ...state.employeeData, ...action.payload.data };
      }
    },
    [updateEmployeeRequest.rejected]: (state, action) => {
      state.updateHrRequest.state = 'failed';
      state.updateHrRequest.error = action.error.message;
    },

    [deleteEmployeeRequest.pending]: (state, action) => {
      state.deleteHrRequest.state = 'loading';
      state.deleteHrRequest.error = '';
    },
    [deleteEmployeeRequest.fulfilled]: (state, action) => {
      state.deleteHrRequest.data = action.payload.data;
      state.deleteHrRequest.state = 'succeeded';
      if (action.payload.data.patientType !== 'dependent') {
        state.employeeData = { ...state.employeeData, ...action.payload.data };
      }
    },
    [deleteEmployeeRequest.rejected]: (state, action) => {
      state.deleteHrRequest.state = 'failed';
      state.deleteHrRequest.error = action.error.message;
    },
    [addDepdendentHrReq.pending]: (state, action) => {
      state.addDependentReq.state = 'loading';
      state.addDependentReq.error = '';
    },
    [addDepdendentHrReq.fulfilled]: (state, action) => {
      state.addDependentReq.data = action.payload.data;
      state.addDependentReq.state = 'succeeded';
    },
    [addDepdendentHrReq.rejected]: (state, action) => {
      state.addDependentReq.state = 'failed';
      state.addDependentReq.error = action.error.message;
    },

    [getEmployeeDataReq.pending]: (state, action) => {
      state.empDataReq.state = 'loading';
      state.empDataReq.error = '';
    },
    [getEmployeeDataReq.fulfilled]: (state, action) => {
      state.empDataReq.data = action.payload.data;
      state.empDataReq.state = 'succeeded';
      state.employeeData = action.payload.data;
    },
    [getEmployeeDataReq.rejected]: (state, action) => {
      state.empDataReq.state = 'failed';
      state.empDataReq.error = action.error.message;
    },

    [addDepInEmp.pending]: (state, action) => {
      state.addDepInEmp.state = 'loading';
      state.addDepInEmp.error = '';
    },
    [addDepInEmp.fulfilled]: (state, action) => {
      state.addDepInEmp.data = action.payload.data;
      state.addDepInEmp.state = 'succeeded';
    },
    [addDepInEmp.rejected]: (state, action) => {
      state.addDepInEmp.state = 'failed';
      state.addDepInEmp.error = action.error.message;
    },
    [postEnrolmentData.pending]: (state, action) => {
      state.addEnrolmentData.state = 'loading';
      state.addEnrolmentData.error = '';
    },
    [postEnrolmentData.fulfilled]: (state, action) => {
      state.addEnrolmentData.state = 'succeeded';
      state.addEnrolmentData.data = action.payload?.data;
    },
    [postEnrolmentData.rejected]: (state, action) => {
      state.addEnrolmentData.state = 'failed';
    },
    'common/cleanup': (state, action) => {
      state.addHrRequest = {
        ...apiState,
      };
      state.employeePendingMapList = null;
      state.employeePendingList = {
        ...apiState,
      };
      state.employeeData = null;
      state.updateHrRequest = {
        ...apiState,
      };
      state.deleteHrRequest = {
        ...apiState,
      };
      state.updateDependentReq = {
        ...apiState,
      };
      state.empDataReq = {
        ...apiState,
      };
      state.addDependentReq = {
        ...apiState,
      };
    },
  },
});

export const selectAddHrRequest = (state) => state.hrRequest.addHrRequest;
export const selectEmployeePendingList = (state) =>
  state.hrRequest.employeePendingList;
export const selectEmpPendingMapList = (state) =>
  state.hrRequest.employeePendingMapList;
export const selectUpdateHrRequest = (state) => state.hrRequest.updateHrRequest;
export const selectDeleteHrRequest = (state) => state.hrRequest.deleteHrRequest;
export const selectSelectedEmployeeData = (state) =>
  state.hrRequest.employeeData;
export const selectAddDepHrRequest = (state) => state.hrRequest.addDependentReq;
export const selectEmpReqData = (state) => state.hrRequest.empDataReq;
export const selectDepInEmp = (state) => state.hrRequest.addDepInEmp;
export const selectEnrolmentData = (state) => state.hrRequest.addEnrolmentData;

export const { setEmployeeData, clearHrReqState, clearEnrolmentState } =
  hrRequestSlice.actions;

export default hrRequestSlice.reducer;
