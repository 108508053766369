export const handleGTLTopUpCalculation = (gtlToUpMultiplier, empData) => {
    const ratePerMil = caluclateAgeWiseRatePerMil(empData?.dob, empData?.gender);
    let gtlTopUpPremium = ((empData?.ctc * gtlToUpMultiplier) / 1000) * ratePerMil;
    let gtlTopUpPremiumGST = gtlTopUpPremium * 0.18;
    gtlTopUpPremium = gtlTopUpPremium + gtlTopUpPremiumGST;
    return Math.round(parseFloat(gtlTopUpPremium)).toFixed(2);
};

export const caluclateAgeWiseRatePerMil = (dob, gender) => {
    const age = calculateAge(dob);
    if (age < 18 || age > 60) {
        return 0;
    }
    const rate = gtlTopUpRates[age][gender];
    return rate;
};

const gtlTopUpRates = {
    18: {
        male: 0.444,
        female: 0.41
    },
    19: {
        male: 0.467,
        female: 0.429
    },
    20: {
        male: 0.467,
        female: 0.444
    },
    21: {
        male: 0.475,
        female: 0.457
    },
    22: {
        male: 0.48,
        female: 0.467
    },
    23: {
        male: 0.484,
        female: 0.475
    },
    24: {
        male: 0.489,
        female: 0.48
    },
    25: {
        male: 0.492,
        female: 0.484
    },
    26: {
        male: 0.496,
        female: 0.489
    },
    27: {
        male: 0.502,
        female: 0.492
    },
    28: {
        male: 0.508,
        female: 0.496
    },
    29: {
        male: 0.517,
        female: 0.502
    },
    30: {
        male: 0.529,
        female: 0.508
    },
    31: {
        male: 0.543,
        female: 0.517
    },
    32: {
        male: 0.56,
        female: 0.529
    },
    33: {
        male: 0.582,
        female: 0.543
    },
    34: {
        male: 0.606,
        female: 0.56
    },
    35: {
        male: 0.637,
        female: 0.582
    },
    36: {
        male: 0.67,
        female: 0.606
    },
    37: {
        male: 0.71,
        female: 0.637
    },
    38: {
        male: 0.756,
        female: 0.67
    },
    39: {
        male: 0.808,
        female: 0.71
    },
    40: {
        male: 0.868,
        female: 0.756
    },
    41: {
        male: 0.938,
        female: 0.808
    },
    42: {
        male: 1.019,
        female: 0.868
    },
    43: {
        male: 1.114,
        female: 0.938
    },
    44: {
        male: 1.222,
        female: 1.019
    },
    45: {
        male: 1.346,
        female: 1.114
    },
    46: {
        male: 1.486,
        female: 1.222
    },
    47: {
        male: 1.643,
        female: 1.346
    },
    48: {
        male: 1.816,
        female: 1.486
    },
    49: {
        male: 2.002,
        female: 1.643
    },
    50: {
        male: 2.2,
        female: 1.816
    },
    51: {
        male: 2.409,
        female: 2.002
    },
    52: {
        male: 2.624,
        female: 2.2
    },
    53: {
        male: 2.848,
        female: 2.409
    },
    54: {
        male: 3.078,
        female: 2.624
    },
    55: {
        male: 3.318,
        female: 2.848
    },
    56: {
        male: 3.569,
        female: 3.078
    },
    57: {
        male: 3.835,
        female: 3.318
    },
    58: {
        male: 4.119,
        female: 3.569
    },
    59: {
        male: 4.428,
        female: 3.835
    },
    60: {
        male: 4.764,
        female: 4.119
    }
};

const calculateAge = (dob) => {
    const dobDate = new Date(dob?.seconds * 1000); // Convert to milliseconds
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dobDate.getFullYear();

    // Check if the birthday hasn't occurred yet this year
    if (
        currentDate.getMonth() < dobDate.getMonth() ||
        (currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())
    ) {
        age--;
    }

    return age;
};

const opdPremiumValues = {
    5000: 3133,
    10000: 5964,
    20000: 11622
};

export const handleOPDPremiumValues = (opdSumInsured) => {
    const premium = opdPremiumValues[opdSumInsured] || 0;
    return premium;
};
