import React, { useEffect } from "react";

import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

import "./Navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { cleanup } from "../../redux/store";
import { selectHrUser } from "../../redux/slices/usersSlice";

const Navbar = () => {
  const history = useHistory();
  const { logout, setLoggedInUser } = useAuth();
  const hrData = useSelector(selectHrUser);
  const dispatch = useDispatch();

  const onClickLogout = async () => {
    try {
      if (window.confirm("Are you sure you want to logout? ")) {
        setLoggedInUser(null);
        dispatch(cleanup());
        await logout();
        history.push("/login");
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <div className="nav-container">
      <nav className="nav d-flex justify-content-between align-items-center">
        <img src="/assets/icons/skflogo.png" alt="logo" className="logo" />
        <p className="logout" onClick={onClickLogout}>
          Logout
        </p>
      </nav>
    </div>
  );
};

export default Navbar;
