import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AppConstants } from "../../common/AppConstants";
import LoopApiService from "../../services/network/LoopApiService";

const apiState = {
  status: "idle",
  data: null,
  error: "",
};

export const fetchCompanyData = createAsyncThunk(
  "company/getCompanyData",
  async ({ companyId }) => {
    const result = await LoopApiService.getCompanyData(companyId);
    return result;
  }
);
//Note: Fetch the list of employees
export const fetchEmployeeDataList = createAsyncThunk(
  "company/fetchEmployeeDataList",
  async ({ companyId }) => {
    const result = await LoopApiService.getEmployeeData(companyId);
    return { data: result };
  }
);
//Note: Fetch the employee data
export const fetchEmployeeData = createAsyncThunk(
  "company/fetchEmployeeData",
  async ({ userId, companyId }) => {
    const result = await LoopApiService.fetchEmployeeData(userId, companyId);
    return { data: result };
  }
);

export const fetchDependentList = createAsyncThunk(
  "company/fetchDependentList",
  async ({ employerId }) => {
    try {
      const result = await LoopApiService.fetchDependentApi(employerId);
      return { data: result && result.data ? result.data : [] };
    } catch (error) {
      console.log("dependent list error:", error);
    }
  }
);

// Fetch dependent list of same parentId
export const fetchHrRequestDependent = createAsyncThunk(
  "hrRequest/fetchHrRequestDependent",
  async ({ parentId, dependentData }) => {
    const data = await LoopApiService.getHrRequestDependent(parentId);
    return { data: data ?? [], dependentData };
  }
);

const companySlice = createSlice({
  name: "companyProfile",
  initialState: {
    companyData: null,
    status: "idle",
    error: "",
    employeeListData: {
      ...apiState,
    },
    employeeData: {
      ...apiState,
    },
    dependentListData: {
      ...apiState,
    },
    dependentRequestState: {
      ...apiState,
    },
  },
  reducers: {
    setDependentList(state, action) {
      state.dependentListData.data = action.payload.data;
    },
    addDependentList(state, action) {
      state.dependentListData.data = [
        ...(state.dependentListData.data ?? []),
        action.payload.data,
      ];
    },
    clearDependentState(state) {
      state.dependentListData.status = "idle";
    },
  },
  extraReducers: {
    [fetchCompanyData.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchCompanyData.fulfilled]: (state, action) => {
      state.companyData = action.payload;
      state.status = "succeeded";
    },
    [fetchCompanyData.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchEmployeeDataList.pending]: (state, action) => {
      state.employeeListData.status = "loading";
      state.employeeListData.error = "";
    },
    [fetchEmployeeDataList.fulfilled]: (state, action) => {
      state.employeeListData.status = "succeeded";
      state.employeeListData.data = action.payload.data;
    },
    [fetchEmployeeDataList.rejected]: (state, action) => {
      state.employeeListData.status = "failed";
      state.employeeListData.error = action.error.message;
    },
    [fetchEmployeeData.pending]: (state, action) => {
      state.employeeData.status = "loading";
      state.employeeData.error = "";
    },
    [fetchEmployeeData.fulfilled]: (state, action) => {
      state.employeeData.status = "succeeded";
      state.employeeData.data = action.payload.data;
    },
    [fetchEmployeeData.rejected]: (state, action) => {
      state.employeeData.status = "failed";
      state.employeeData.error = action.error.message;
    },

    [fetchDependentList.pending]: (state, action) => {
      state.dependentListData.status = "loading";
      state.dependentListData.error = "";
    },
    [fetchDependentList.fulfilled]: (state, action) => {
      state.dependentListData.status = "succeeded";
      state.dependentListData.data = action.payload.data;
    },
    [fetchDependentList.rejected]: (state, action) => {
      state.dependentListData.status = "failed";
      state.dependentListData.error = action.error.message;
      state.dependentListData.data = [];
    },
    [fetchHrRequestDependent.pending]: (state, action) => {
      state.dependentRequestState.status = "loading";
      state.dependentRequestState.error = "";
    },
    [fetchHrRequestDependent.fulfilled]: (state, action) => {
      state.dependentRequestState.status = "succeeded";
      let data = [];
      // Dependent data from Users collection
      let empDepData = action.payload.dependentData;
      // Request dependent data from "hrRequest" collection
      let reqDepData = action.payload.data;
      if (!empDepData?.length) {
        // When dependents from user collection is empty then we'll store only dependent list from hrRequest collection.
        data = [...reqDepData];
      } else if (!reqDepData?.length) {
        // When dependent list from hrRequest collection is empty then we'll store only user Collection dep list.
        data = [...empDepData];
      } else {
        empDepData.forEach((item) => {
          if (reqDepData?.length > 0) {
            // Finds the existing dependent from user collection in the hr request list, basically we will add the update and delete requests.
            const depItem = reqDepData.find(
              (value) => value.userId === item.userId
            );
            if (depItem) {
              let body = {
                ...item,
                ...depItem,
              };
              data.push(body);
            } else {
              data.push(item);
            }
            // Here we will add the add requests
            reqDepData.forEach((reqItem) => {
              if (reqItem.operation === AppConstants.ADD_DEPENDENT) {
                data.push(reqItem);
              }
            });
          }
        });
      }
      state.dependentListData.data = data;
    },
    [fetchHrRequestDependent.rejected]: (state, action) => {
      state.dependentRequestState.status = "failed";
      state.dependentRequestState.error = action.error.message;
    },
    "common/cleanup": (state, action) => {
      state.companyData = null;
      state.status = "idle";
      state.error = "";
      state.employeeListData = {
        ...apiState,
      };
      state.employeeData = {
        ...apiState,
      };
      state.dependentListData = {
        ...apiState,
      };
    },
  },
});

export const selectCompanyData = (state) => state.companyProfile.companyData;
export const selectEmployeeListData = (state) =>
  state.companyProfile.employeeListData;
export const selectEmployeeData = (state) => state.companyProfile.employeeData;
export const selectDependentList = (state) =>
  state.companyProfile.dependentListData;

export const { setDependentList, addDependentList, clearDependentState } =
  companySlice.actions;

export default companySlice.reducer;
