import { configureStore, createAction } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import createMigrate from "redux-persist/es/createMigrate";

export const cleanup = createAction("common/cleanup");
const migrations = {
  0: (state) => {
    // migration clear out device state
    return {
      ...state,
      device: undefined,
    };
  },
  1: (state) => {
    // migration to keep only device state
    return {
      device: state.device,
    };
  },
};
//Note: Increase the version code always before the deployment.
const persistConfig = {
  key: "root",
  version: process.env.REACT_APP_VERSION,
  storage,
  blacklist: ["addEmpForm"],
  migrate: createMigrate(migrations, { debug: false }),
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    return process.env.NODE_ENV !== "production"
      ? defaultMiddleware.concat(logger)
      : defaultMiddleware;
  },
});

export const persistor = persistStore(store);
export default store;
