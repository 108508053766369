import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { setLoopIdToken } from "../../common/CustomFirebase";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import { SideLayout } from "../../components/UI/SideLayout";
import { useAuth } from "../../contexts/AuthContext";
import LoopApiServiceContext from "../../contexts/LoopApiServiceContext";
import { auth } from "../../firebase";
import {
  clearHrUpdate,
  fetchHrUser,
  selectConfirmationResult,
  selectHrUser,
  selectUpdateUser,
  setClearHrData,
  setConfirmationResult,
} from "../../redux/slices/usersSlice";
import "../Login.scss";

const EnterOtp = () => {
  const [otpCode, setOtpCode] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [validOtp, setValidOtp] = useState(false);
  const { setLoggedInUser } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const mobile = (location && location.state && location.state.mobile) || "";
  const confirmationResult = useSelector(selectConfirmationResult);
  const hrData = useSelector(selectHrUser);
  const updateHrValue = useSelector(selectUpdateUser);
  const firebaseContext = useContext(LoopApiServiceContext);

  useEffect(() => {
    window.recaptchaVerifier = new firebaseContext.captcha.RecaptchaVerifier(
      "otp-button",
      {
        size: "invisible",
      }
    );
  }, [
    firebaseContext.auth.RecaptchaVerifier,
    firebaseContext.captcha.RecaptchaVerifier,
  ]);

  useEffect(() => {
    if (hrData && hrData.data && (hrData.data.email || hrData.data.mobile)) {
      setLoopIdToken(hrData.data.userId);
      //email is already present need to redirect to hr dashboard.
      setLoading(false);
      setLoggedInUser({ ...hrData.data });
      history.push("/");
    }
  }, [
    dispatch,
    history,
    mobile,
    setLoggedInUser,
    hrData,
    hrData.data,
    validOtp,
  ]);

  useEffect(() => {
    if (updateHrValue && updateHrValue.state === "succeeded") {
      dispatch(
        fetchHrUser({
          methodType: "mobile",
          userId: mobile,
          loginMethod: "mobile",
        })
      );
      // setLoggedInUser({ ...userData.data, mobile: mobile });
      // history.push("/");
      dispatch(clearHrUpdate());
    }
  }, [history, hrData.data, mobile, setLoggedInUser, dispatch, updateHrValue]);

  const handleChange = (otp) => {
    setOtpCode(otp);
  };
  const sentOtpToVerify = () => {
    setLoading(true);
    setInvalidOtp(false);
    setValidOtp(false);
    if (confirmationResult && confirmationResult.confirm) {
      confirmationResult
        .confirm(otpCode)
        .then((result) => {
          if (hrData && !hrData.data) {
            try {
              dispatch(
                fetchHrUser({
                  methodType: "mobile",
                  userId: mobile,
                  loginMethod: "mobile",
                })
              );
            } catch (error) {
              setLoading(false);
              console.log("phone auth error:", error);
            }
          }
          setValidOtp(true);
          dispatch(setConfirmationResult({ data: null }));
        })
        .catch((error) => {
          setLoading(false);
          console.log("phone login error:", error);
          if (error.code === "auth/invalid-verification-code") {
            setInvalidOtp(true);
          }
        });
    } else {
      dispatch(setConfirmationResult({ data: null }));
      dispatch(setClearHrData());
      history.goBack();
    }
  };
  return (
    <>
      <div className="login-container">
        {loading && <Backdrop />}
        <SideLayout />
        <div className="login right-box-content">
          <div className="lh-login-form">
            <h4 className="login-title">Verify number</h4>
            <p className="intro-subtitle mb-4">
              Please enter the OTP shared on <strong>{mobile}</strong>{" "}
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(setConfirmationResult({ data: null }));
                  dispatch(setClearHrData());
                  history.goBack();
                }}
                src="/assets/icons/edit.svg"
                alt=""
              />
            </p>

            <div className="row">
              <div
                className={`col-md-12 otp-input-container ${
                  invalidOtp ? "otp-input-container-error" : ""
                }${validOtp ? "otp-input-container-valid" : ""}`}
              >
                <OtpInput
                  value={otpCode}
                  onChange={handleChange}
                  numInputs={6}
                  inputStyle={styles.inputContainer}
                />
                {invalidOtp ? (
                  <div className="invalid-otp-text">
                    Please enter the correct OTP
                  </div>
                ) : null}
              </div>
            </div>

            <Button
              id="otp-button"
              disabled={!otpCode || otpCode.length !== 6}
              className="lh-login-submit-button"
              onClick={sentOtpToVerify}
            >
              Verify
            </Button>

            <Button
              id="otp-button"
              className="lh-login-submit-button tranparent-btn"
              onClick={async () => {
                setLoading(true);
                try {
                  const confirmationData = await auth.signInWithPhoneNumber(
                    "+91" + mobile,
                    window.recaptchaVerifier
                  );

                  dispatch(setConfirmationResult({ data: confirmationData }));
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                  alert("Error: " + error?.message);
                  console.log("Error in sign in with phone:", error);
                }
              }}
            >
              Resend OTP
            </Button>
          </div>
          If you face any issue while login please contact us: 020-7117-3585
        </div>
      </div>
    </>
  );
};

const styles = {
  inputContainer: {
    width: 40,
    margin: 10,
  },
  container: {
    backgroundColor: "white",
    height: "100%",
  },
};

export default EnterOtp;
