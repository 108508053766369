import React, { useEffect, useState } from "react";
import { EmployeeItem } from "../components/EmployeeItem";
import Navbar from "../components/Navbar/Navbar";
import "./ViewEmployeeData.css";
import { useHistory } from "react-router-dom";
import { AlertModal } from "../components/AlertModal";
import * as moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDependentState,
  fetchDependentList,
  fetchHrRequestDependent,
  selectDependentList,
  setDependentList,
} from "../redux/slices/companySlice";
import { selectHrUser } from "../redux/slices/usersSlice";
import {
  clearHrReqState,
  deleteEmployeeRequest,
  fetchHrRequestList,
  selectDeleteHrRequest,
  selectEmpPendingMapList,
  selectSelectedEmployeeData,
  selectUpdateHrRequest,
  addDepdendentHrReq,
  selectAddDepHrRequest,
  getEmployeeDataReq,
  selectEmpReqData,
  addDepInEmp,
  selectDepInEmp,
} from "../redux/slices/hrRequestSlice";
import {
  fetchPolicyList,
  selectPolicyList,
} from "../redux/slices/policyListSlice";
import Backdrop from "../components/UI/Backdrop/Backdrop";
import { AppConstants } from "../common/AppConstants";
import { AddEmployeeForm } from "../components/AddEmployeeForm";
import { Collapse } from "@material-ui/core";
import {
  selectCurrentOperation,
  setClearForm,
  setCurrentOperation,
  setEditForm,
} from "../redux/slices/addEmpFormSlice";

const TIMER = 500;

const ViewEmployeeData = () => {
  const history = useHistory();
  const [showMessage, setShowMessage] = useState(false);
  const [showDeleteBackdrop, setShowDeleteBackdrop] = useState(false);
  const [deletingUser, setDeletingUser] = useState({});
  const hrData = useSelector(selectHrUser);
  const dispatch = useDispatch();
  const dependentList = useSelector(selectDependentList);
  const EmpRequestMap = useSelector(selectEmpPendingMapList);
  const updateHrRequest = useSelector(selectUpdateHrRequest);
  const deleteHrRequest = useSelector(selectDeleteHrRequest);
  const empDetail = useSelector(selectSelectedEmployeeData);
  const [isAddDependent, setIsAddDependent] = useState(false);
  const addDepHrRequest = useSelector(selectAddDepHrRequest);
  const currentOperation = useSelector(selectCurrentOperation);
  const policyListState = useSelector(selectPolicyList);
  const addDependentInEmployee = useSelector(selectDepInEmp);

  //Note: empHrRequestData contains a single fetched data from HrRequest collection using docRefId.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      empDetail &&
      empDetail.status === AppConstants.PENDING &&
      empDetail.operation === AppConstants.ADD
    ) {
      //NOTE: Here the empData and dependent list is visible if the request is for Addition by HR.
      dispatch(clearDependentState());
      //NOTE: The dependentData in the object is empty because there's no previous dependent to that user as this user is new.
      dispatch(
        fetchHrRequestDependent({
          parentId: empDetail.userId,
          dependentData: [],
        })
      );
    }
  }, [dispatch, empDetail]);

  useEffect(() => {
    if (deleteHrRequest && deleteHrRequest.state === "succeeded") {
      setShowMessage(true);
      dispatch(clearHrReqState({ data: AppConstants.DELETE }));
      let body = { companyId: hrData.data.companyId };
      dispatch(fetchHrRequestList(body));
    }
  }, [deleteHrRequest, dispatch, hrData.data.companyId]);

  useEffect(() => {
    if (updateHrRequest && updateHrRequest.state === "succeeded") {
      let body = { companyId: hrData.data.companyId };
      let messageBody = {
        error: false,
        success: true,
      };
      dispatch(clearHrReqState({ data: AppConstants.UPDATE }));
      dispatch(setCurrentOperation({ data: messageBody }));
      dispatch(fetchHrRequestList(body));
    }
  }, [
    updateHrRequest,
    dispatch,
    hrData.data.companyId,
    currentOperation.userId,
  ]);

  useEffect(() => {
    if (addDepHrRequest.state === "succeeded") {
      let messageBody = {
        error: false,
        success: true,
      };
      //Below setCurrentOperation: is just showing message in Employee list item.
      dispatch(setCurrentOperation({ data: messageBody }));
      dispatch(clearHrReqState({ data: AppConstants.ADD_DEPENDENT }));
      dispatch(getEmployeeDataReq({ docRef: empDetail.docRefId }));
      setIsAddDependent(false);
      dispatch(setEditForm({ data: false }));
    }
  }, [addDepHrRequest, dispatch, empDetail]);

  useEffect(() => {
    if (addDependentInEmployee.state === "succeeded") {
      let messageBody = {
        error: false,
        success: true,
      };
      //Below setCurrentOperation: is just showing message in Employee list item.
      dispatch(setCurrentOperation({ data: messageBody }));
      dispatch(clearHrReqState({ data: AppConstants.ADD_DEPENDENT }));
      setIsAddDependent(false);
      dispatch(setEditForm({ data: false }));
    }
  }, [addDependentInEmployee.state, dispatch, empDetail]);

  useEffect(() => {
    if (
      empDetail &&
      (!empDetail.operation || empDetail.operation !== AppConstants.ADD)
    ) {
      dispatch(fetchDependentList({ employerId: empDetail.userId }));
    }
    if (empDetail && empDetail.userId) {
      //Note: cors issue need to fix
      dispatch(fetchPolicyList({ userId: empDetail.userId }));
    }
  }, [dispatch, empDetail]);

  useEffect(() => {
    if (dependentList.status === "succeeded") {
      dispatch(clearDependentState());
      dispatch(
        fetchHrRequestDependent({
          parentId: empDetail.userId,
          dependentData: dependentList.data,
        })
      );
    }
  }, [dispatch, dependentList, empDetail]);

  const handleDeleteBackdrop = (user) => {
    setDeletingUser(user);
    return setShowDeleteBackdrop(true);
  };

  const hideBackdrop = () => {
    return setShowDeleteBackdrop(false);
  };
  useEffect(() => {
    if (isAddDependent) {
      setTimeout(() => {
        window.scrollTo(
          0,
          document.querySelector(".dependent-container").scrollHeight
        );
      }, TIMER);
    }
  }, [isAddDependent]);

  const deleteMember = (empData) => {
    let body = {
      ...empData,
      active: true,
      status: AppConstants.PENDING,
      updatedAt: new Date(),
      hrId: hrData.data && hrData.data.id,
      hrEmployerId: hrData.data && hrData.data.companyId,
    };
    //Note: Below data will be added as update request in hrRequest collection.
    if (empData.patientType === "dependent") {
      body = {
        ...body,
        patientType: "dependent",
        operation: AppConstants.DELETE_DEPENDENT,
        employeeId: empDetail?.employeeId,
      };
    } else {
      body = {
        ...body,
        patientType: "membership",
        operation: AppConstants.DELETE,
      };
    }
    dispatch(deleteEmployeeRequest({ data: body }));
  };
  const onUpdateDepdendent = (data) => {
    let body = {
      ...data,
      userType: "patient",
      patientType: "dependent",
      parentId: empDetail.userId,
      employeeId: empDetail.employeeId,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    dispatch(addDepInEmp({ data: body }));
  };

  return (
    <div>
      <Navbar />
      {(updateHrRequest.state === "loading" ||
        deleteHrRequest.state === "loading" ||
        addDepHrRequest.state === "loading" ||
        dependentList.status === "loading" ||
        addDependentInEmployee.state === "loading" ||
        policyListState.status === "loading") && <Backdrop />}
      <div className="view-parent-container">
        <div className="view-detaill-emp-container col-md-8">
          <div
            className="view-emp-header hover"
            onClick={() => {
              history.goBack();
            }}
          >
            <img
              className="arrow-left-emp-img"
              src="/assets/icons/chevron-right.svg"
              alt="edit-icon"
            />
            <span className="heading-bold-text">Employee Details</span>
          </div>
          <div className="dependent-container">
            {empDetail && (
              <EmployeeItem
                firstName={empDetail.firstName}
                lastName={empDetail.lastName}
                employeeId={(empDetail && empDetail.employeeId) || ""}
                relationShip={
                  empDetail && empDetail.patientType
                    ? empDetail.patientType === "membership"
                      ? "Self"
                      : empDetail.relationship
                    : ""
                }
                dob={empDetail.dob}
                gender={empDetail.gender}
                number={empDetail.mobile}
                email={empDetail.email}
                id={empDetail.docRefId}
                isDependent={false}
                user={empDetail}
                operation={empDetail && empDetail.operation}
                handleDeleteBackdrop={handleDeleteBackdrop}
                policyData={
                  policyListState &&
                  policyListState.data &&
                  policyListState.data[empDetail.userId] &&
                  policyListState.data[empDetail.userId].eCardData.length > 0
                    ? policyListState.data[empDetail.userId].eCardData
                    : null
                }
                disable={
                  empDetail &&
                  empDetail.patientType === AppConstants.MEMBERSHIP &&
                  empDetail.status === AppConstants.PENDING &&
                  !empDetail.operation.includes(AppConstants.CHECK_DEPENDENT)
                }
                issuedOn={
                  empDetail.createdAt &&
                  moment(empDetail.createdAt.seconds * 1000).format(
                    "YYYY-MM-DD"
                  )
                }
              />
            )}
          </div>
          <div className="dependent-header-container">
            <span className="heading-bold-text">Dependent Details</span>
            {empDetail &&
            (!empDetail.operation ||
              empDetail.operation !== AppConstants.DELETE) ? (
              <div
                className="view-emp-add-dep-container hover"
                onClick={() => {
                  setIsAddDependent(!isAddDependent);
                  dispatch(setEditForm({ data: true }));
                  dispatch(setClearForm({ data: true }));
                }}
              >
                <img
                  className="arrow-left-emp-img"
                  src="/assets/icons/plus-outline.svg"
                  alt="edit-icon"
                />
                <span className="add-dependent-text">Add Dependent</span>
              </div>
            ) : null}
          </div>
          <Collapse in={isAddDependent} timeout={TIMER}>
            <div className="mx-auto">
              <AddEmployeeForm
                empDocRef={empDetail && empDetail.docRefId}
                setVisibleForm={setIsAddDependent}
                isDependent={true}
                userType={AppConstants.DEPENDENT}
                onGetFormData={(data) => onUpdateDepdendent(data)}
                parentEmpId={empDetail?.employeeId}
              />
            </div>
          </Collapse>
          {dependentList &&
          dependentList.data &&
          dependentList.data.length > 0 ? (
            <div id="dependent-list" className="dependent-container">
              {dependentList.data.map((item, index) => {
                //Here overrides the dependent data from HrRequest collection dependent data
                const depItem =
                  item.userId && EmpRequestMap && EmpRequestMap[item.userId]
                    ? EmpRequestMap[item.userId]
                    : item;
                return (
                  <EmployeeItem
                    key={index.toString()}
                    firstName={depItem.firstName}
                    lastName={depItem.lastName}
                    relationShip={depItem.relationship}
                    dob={depItem.dob}
                    gender={depItem.gender}
                    number={depItem.mobile}
                    email={depItem.email}
                    isDependent={true}
                    id={depItem.userId}
                    employeeId={empDetail?.employeeId || empDetail?.userId}
                    policyData={
                      policyListState?.data?.[item.userId]?.eCardData?.length >
                      0
                        ? policyListState.data[item.userId].eCardData
                        : null
                    }
                    operation={
                      (depItem && depItem.operation) ||
                      (empDetail.operation === AppConstants.DELETE
                        ? AppConstants.DELETE_DEPENDENT
                        : "")
                    }
                    user={depItem}
                    disable={
                      depItem.status === AppConstants.PENDING ||
                      empDetail.operation === AppConstants.DELETE
                    }
                    handleDeleteBackdrop={handleDeleteBackdrop}
                    issuedOn={
                      depItem.createdAt &&
                      moment(depItem.createdAt.seconds * 1000).format(
                        "YYYY-MM-DD"
                      )
                    }
                  />
                );
              })}
            </div>
          ) : null}
        </div>
        <AlertModal
          subTitle={`Are you sure you want to delete ${
            deletingUser.firstName + " " + deletingUser.lastName
          }? ${
            deletingUser.patientType === "dependent"
              ? "This action will terminate policy cover for this dependent."
              : "This action will terminate policy cover for this employee and all their dependents."
          }`}
          buttonText1={"Delete"}
          buttonText2={"Cancel"}
          showModal={showDeleteBackdrop}
          setShowModal={setShowDeleteBackdrop}
          onPressButton2={hideBackdrop}
          variant="danger"
          onPressButton1={() => {
            setShowDeleteBackdrop(false);
            deleteMember(deletingUser);
          }}
        />
        <AlertModal
          subTitle={`Your request to delete ${
            deletingUser.patientType === "dependent" ? "dependent" : "employee"
          } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`}
          buttonText1={"Ok"}
          showModal={showMessage}
          setShowModal={setShowMessage}
          onPressButton1={() => {
            setShowMessage(false);
          }}
        />
      </div>
    </div>
  );
};

export default ViewEmployeeData;
