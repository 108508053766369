import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectHrUser } from "../redux/slices/usersSlice";
import useQuery from "../hooks/useQuery";

const RedirectAuthRoute = ({ component: Component, rest }) => {
  const userData = useSelector(selectHrUser);
  const query = useQuery();
  const redirectTo = query.get("next") || "/";

  return (
    <Route
      {...rest}
      render={(props) =>
        userData &&
        userData.data &&
        userData.data.email &&
        userData.data.mobile ? (
          <Redirect to={redirectTo} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default RedirectAuthRoute;
