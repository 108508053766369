import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoopApiService from "../../services/network/LoopApiService";
const apiState = {
  status: "idle",
  data: null,
  error: "",
};

export const fetchPolicyList = createAsyncThunk(
  "policyList/fetchPolicyList",
  async ({ userId }) => {
    let result = null;
    try {
      result = await LoopApiService.getPolicyList(userId);
      result = getPolicyData(result);
    } catch (error) {
      console.log("fetch policy list issue:", error);
    }
    return { data: result };
  }
);

const policyListSlice = createSlice({
  name: "policyList",
  initialState: {
    policyListData: {
      ...apiState,
    },
  },
  reducers: {},
  extraReducers: {
    [fetchPolicyList.pending]: (state, action) => {
      state.policyListData.status = "loading";
      state.policyListData.error = "";
    },
    [fetchPolicyList.fulfilled]: (state, action) => {
      state.policyListData.data = action.payload.data;
      state.policyListData.status = "succeeded";
    },
    [fetchPolicyList.rejected]: (state, action) => {
      state.policyListData.status = "failed";
      state.policyListData.error = action.error.message;
    },
    "common/cleanup": (state, action) => {
      state.policyListData = {
        ...apiState,
      };
    },
  },
});
// Note: Below functions filter the member list policies and created key value object
// where key will be the userId and value will be the whole value.
// The intention of doing this is to store different policies to the same user object.
const getPolicyData = (result) => {
  if (!result || result.length === 0) {
    return null;
  }
  let data = {};
  for (let value of result) {
    if (data[value.userId]) {
      data[value.userId].eCardData = [...data[value.userId].eCardData, value];
    } else {
      data[value.userId] = {
        eCardData: [value],
      };
    }
  }
  return data;
};

export const selectPolicyList = (state) => state.policyList.policyListData;

export default policyListSlice.reducer;
