import { combineReducers } from "redux";
import addEmpFormSlice from "./slices/addEmpFormSlice";
import companySlice from "./slices/companySlice";
import hrRequestSlice from "./slices/hrRequestSlice";
import policyListSlice from "./slices/policyListSlice";
import usersSlice from "./slices/usersSlice";

const rootReducer = combineReducers({
  companyProfile: companySlice,
  users: usersSlice,
  addEmpForm: addEmpFormSlice,
  hrRequest: hrRequestSlice,
  policyList: policyListSlice,
});

export default rootReducer;
