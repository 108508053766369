import React, { useEffect, useState } from "react";

import Backdrop from "../components/UI/Backdrop/Backdrop";
import Message from "../components/Message/Message";
import Navbar from "../components/Navbar/Navbar";

import firebase from "../firebase";

import { Link } from "react-router-dom";
import { AlertModal } from "../components/AlertModal";
import { useHistory } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import { AppConstants } from "../common/AppConstants";

const EditDependentScreen = ({ match }) => {
  const userId = match.params.id;

  var db = firebase.firestore();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const history = useHistory();
  const { currentUser } = useAuth();

  useEffect(() => {
    function getUserData() {
      setLoading(true);
      db.collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          let data = doc.data();

          setFirstName(data.firstName);
          setLastName(data.lastName);
          setGender(data.gender);
          setDateOfBirth(data.dob);

          setLoading(false);
        });
    }
    getUserData();
  }, [db, userId]);

  const updateUser = (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    var docRef = db.collection("users").doc(userId);

    docRef
      .update({
        hrRequest: {
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          dob: dateOfBirth,
        },
        operation: AppConstants.UPDATE,
        requestBy: currentUser.email,
        status: "pending",
        updatedAt: Date.now(),
      })
      .then(() => {
        setShowMessage(true);
        setSuccessMessage(
          `Your request to update dependent information for ${
            firstName + " " + lastName
          } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`
        );
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage("Error updating user");
        setLoading(false);
      });
  };

  const setGenderVal = (val) => {
    setGender(val);
  };

  return (
    <div>
      <Navbar />
      <div className="add-employee" style={{ height: "90vh" }}>
        <div className="container">
          {loading && <Backdrop />}

          <Link
            onClick={() => {
              history.goBack();
            }}
            className="d-flex justify-content-between align-items-center mt-4"
            style={{
              color: "#0e4da4",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            {`< HR Dashboard`}
          </Link>

          <div className="main">
            <h4>Edit Dependent Details</h4>

            {errorMessage && <Message variant="danger">{errorMessage}</Message>}
            {successMessage && (
              <Message variant="success">{successMessage}</Message>
            )}

            <section className="form-container">
              <div className="field-box">
                <text className="label">
                  First Name<span className="label-span">*</span>
                </text>
                <input
                  type="text"
                  required
                  className="input-box"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="field-box">
                <text className="label">
                  Last Name<span className="label-span">*</span>
                </text>
                <input
                  type="text"
                  required
                  className="input-box"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="field-box">
                <text className="label">
                  Gender<span className="label-span">*</span>
                </text>

                <select
                  name="gender"
                  id="gender"
                  className="input-box"
                  value={gender}
                  onChange={(e) => {
                    setGenderVal(e.target.value);
                  }}
                >
                  <option value="Select">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div className="field-box">
                <text className="label">
                  Account Holder Date of Birth
                  <span className="label-span">*</span>
                </text>
                <input
                  type="date"
                  required
                  className="input-box"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>
            </section>

            {!showMessage && (
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="upload-button"
                  disabled={loading}
                  onClick={updateUser}
                >
                  Submit Modifications to Database
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDependentScreen;
