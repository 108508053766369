import React, { useState } from 'react';
import Backdrop from '../components/UI/Backdrop/Backdrop';
import Message from '../components/Message/Message';
import Navbar from '../components/Navbar/Navbar';

import firebase, { fieldValue } from '../firebase';
import { Link, useHistory } from 'react-router-dom';

import './AddDependentScreen.scss';
import { AlertModal } from '../components/AlertModal';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../contexts/AuthContext';
import moment from 'moment';
import { AppConstants } from '../common/AppConstants';

const AddDependents = ({ match }) => {
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [dependentGender, setDependentGender] = useState('Male');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [relationship, setRelationship] = useState('Parent');
    const [contactNo, setContactNo] = useState(null);

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    const parentId = match.params.userid;
    const accountHolderMobileNo = match.params.mobileno;
    const accountHolderFirstName = match.params.firstname;
    const accountHolderLastName = match.params.lastname;
    const [showMessage, setShowMessage] = useState(false);
    const { currentUser } = useAuth();

    const handleRegisterDependent = (e) => {
        e.preventDefault();

        const db = firebase.firestore();

        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');
        if (!fName || !dateOfBirth || !dependentGender) {
            setLoading(false);
            return setErrorMessage('Please fill the fields');
        }

        db.collection('users')
            .where('parentId', '==', accountHolderMobileNo)
            .where('firstName', '==', fName)
            .where('lastName', '==', lName)
            .get()
            .then(async (querySnapshot) => {
                let existUser = [];
                querySnapshot.forEach((doc) => {
                    existUser.push(doc.data());
                });

                if (!existUser.length) {
                    let docId = uuidv4();
                    let ccRef = db.collection('user');
                    let ccs = (await ccRef.where('userType', '==', 'coordinator').get()).docs.map((doc) => doc.id);
                    let assignedCCId = ccs[Math.floor(Math.random() * ccs.length)];

                    let input = {
                        active: true,
                        userId: docId,
                        hrRequest: {
                            userId: docId,
                            firstName: fName,
                            lastName: lName,
                            gender: dependentGender,
                            dob: moment(dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                            contact: contactNo ? contactNo : null,
                            createdAt: Date.now(),
                            parentId: accountHolderMobileNo,
                            patientType: 'dependent',
                            coordinatorId: assignedCCId,
                            patientEcard: null,
                            relationship: relationship ? relationship : null
                        },
                        operation: AppConstants.ADD,
                        status: 'pending',
                        requestBy: currentUser.email,
                        updatedAt: Date.now()
                    };

                    db.collection('users')
                        .doc(docId)
                        .set(input)
                        .then((docRef) => {
                            setShowMessage(true);
                            const parentUser = db.collection('users').doc(parentId);
                            parentUser.update({
                                dependents: fieldValue.arrayUnion(docId)
                            });
                        });

                    setLoading(false);
                    setSuccessMessage(
                        `Your request to add a new dependent for ${
                            fName + ' ' + lName
                        } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`
                    );
                } else {
                    setLoading(false);
                    setErrorMessage('Dependent already exist');
                }
            });
    };

    return (
        <div>
            <Navbar />
            <div className='add-employee' style={{ height: '90vh' }}>
                <div className='container'>
                    {loading && <Backdrop />}

                    <Link
                        onClick={() => {
                            history.push('/');
                        }}
                        className='d-flex justify-content-between align-items-center mt-4'
                        style={{
                            color: '#0e4da4',
                            cursor: 'pointer',
                            textDecoration: 'none'
                        }}
                    >
                        {`< HR Dashboard`}
                    </Link>

                    <div className='add-dependent-main'>
                        <h4>Add new dependent</h4>

                        {errorMessage && <Message variant='danger'>{errorMessage}</Message>}
                        {successMessage && <Message variant='success'>{successMessage}</Message>}

                        <section className='form-container'>
                            <div className='field-box d-flex flex-column'>
                                <text className='label'>Account Holder First Name</text>
                                <text className='text'>{accountHolderFirstName}</text>
                            </div>
                            <div className='field-box d-flex flex-column'>
                                <text className='label'>Account Holder Last Name</text>
                                <text className='text'>{accountHolderLastName}</text>
                            </div>

                            <div className='field-box d-flex flex-column'>
                                {/* <text className="label">Type</text>
                <text className="text">Membership</text> */}
                            </div>

                            <div className='field-box'>
                                <text className='label'>
                                    Dependent First Name<span className='label-span'>*</span>
                                </text>
                                <input
                                    type='text'
                                    required
                                    style={styles.inputTextStyle('black')}
                                    className='input-box'
                                    onChange={(e) => setFName(e.target.value)}
                                />
                            </div>
                            <div className='field-box'>
                                <text className='label'>
                                    Dependent Last Name<span className='label-span'>*</span>
                                </text>
                                <input
                                    type='text'
                                    required
                                    style={styles.inputTextStyle('black')}
                                    className='input-box'
                                    onChange={(e) => setLName(e.target.value)}
                                />
                            </div>

                            <div className='field-box'>
                                <text className='label'>
                                    Dependent DOB
                                    <span className='label-span'>*</span>
                                </text>
                                <input
                                    type='date'
                                    required
                                    style={styles.inputTextStyle(dateOfBirth ? 'black' : 'rgba(62, 74, 89, 0.5)')}
                                    className='input-box'
                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                />
                            </div>

                            <div className='field-box'>
                                <text className='label'>
                                    Dependent Gender<span className='label-span'>*</span>
                                </text>

                                <select
                                    name='gender'
                                    id='gender'
                                    className='input-box'
                                    style={styles.inputTextStyle('black')}
                                    onChange={(e) => setDependentGender(e.target.value)}
                                >
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                    <option value='Others'>Others</option>
                                </select>
                            </div>

                            <div className='field-box'>
                                <text className='label'>
                                    Relation to Account Holder
                                    <span className='label-span'>*</span>
                                </text>

                                <select
                                    name='gender'
                                    id='gender'
                                    className='input-box'
                                    style={styles.inputTextStyle('black')}
                                    onChange={(e) => setRelationship(e.target.value)}
                                >
                                    <option value='Male'>Parent</option>
                                    <option value='Female'>Spouse / Partner</option>
                                    <option value='Child'>Child</option>
                                </select>
                            </div>

                            <div className='field-box'>
                                <text className='label'>Mobile Number</text>
                                <input
                                    type='number'
                                    className='input-box'
                                    style={styles.inputTextStyle('black')}
                                    onChange={(e) => setContactNo(e.target.value)}
                                />
                            </div>
                        </section>

                        <div className='form-info text-right'>
                            <text>
                                <span className='span'>*</span>
                                Fields are mandatory
                            </text>
                        </div>

                        {!showMessage && (
                            <div className='text-center mt-4'>
                                <button
                                    type='button'
                                    className='upload-button'
                                    disabled={loading}
                                    onClick={handleRegisterDependent}
                                >
                                    Submit Request for Dependent Addition
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <AlertModal
        subTitle={`Your request to add a new dependent for ${
          fName + " " + lName
        } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`}
        buttonText1={"Ok"}
        showModal={showMessage}
        setShowModal={setShowMessage}
        onPressButton1={() => {
          setShowMessage(false);
        }}
      /> */}
        </div>
    );
};

const styles = {
    inputTextStyle: (dateOfBirth) => ({
        color: dateOfBirth
    })
};

export default AddDependents;
