import React from "react";
import { Button, Modal } from "react-bootstrap";
import { CircleProgress } from "react-gradient-progress";

const ModalComponent = ({
  show,
  handleClose,
  length,
  uploadHandler,
  uploading,
  uploadedItemsCount,
  uploadableItemsCount,
  employer,
}) => {
  return (
    <Modal
      className="text-center"
      backdrop="static"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "22px", fontWeight: "bold" }}>
          Upload members to {employer}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        {/* <CircleProgress
          percentage={70}
          strokeWidth={8}
          width={150}
          primaryColor={["#1890FF", "#1890FF"]}
        /> */}
        {uploading ? (
          <>
            {/* <Spinner2 /> */}
            <CircleProgress
              percentage={
                uploadedItemsCount === 0
                  ? 0
                  : Math.ceil((uploadedItemsCount / uploadableItemsCount) * 100)
              }
              strokeWidth={8}
              width={150}
              primaryColor={["#1890FF", "#1890FF"]}
            />
            {uploadedItemsCount ? (
              <h6>
                <b>Uploading {uploadableItemsCount} rows</b>
              </h6>
            ) : (
              <h6>
                Preparing to upload... <br /> (This might take some time)
              </h6>
            )}
          </>
        ) : (
          `Are you sure you want to upload ${length} users to ${employer}?`
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" disabled={uploading} onClick={handleClose}>
          No, don't upload data
        </Button>
        <Button variant="success" disabled={uploading} onClick={uploadHandler}>
          Yes, upload data
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
