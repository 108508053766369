import React from "react";
import Form from "react-bootstrap/Form";
import "./FormInput.scss";

export const FormInput = ({
  value,
  onChange,
  placeholder,
  isInvalid,
  invalidText = "Enter valid value",
  fieldKey = "",
  labelName,
  controlId,
  type = "text",
  maxLength = "250",
  style,
  min,
  max,
  disabled = false,
  onBlur,
  isAddress = false,
  textTransform = "capitalize",
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="input-label-name">{labelName}</Form.Label>
      <Form.Control
        placeholder={placeholder}
        type={type}
        onBlur={onBlur}
        maxLength={maxLength}
        as={isAddress ? "textarea" : "input"}
        className={`login-input ${isInvalid ? "border-red" : ""} ${
          textTransform === "uppercase" ? "text-uppercase" : "text-capitalize"
        }`}
        value={value || ""}
        onChange={(event) => onChange(event.target.value, fieldKey)}
        min={min}
        max={max}
        disabled={disabled}
      />
      {isInvalid ? (
        <Form.Text className="error-message">{invalidText}</Form.Text>
      ) : null}
    </Form.Group>
  );
};
