import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import LoopEndpoints from "./LoopEndpoints";
import LoopHttpException from "../../exceptions/LoopHttpException";
import { AppConstants } from "../../common/AppConstants";
export const fieldValue = firebase.firestore.FieldValue;
export const timeStamp = firebase.firestore.Timestamp;

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class LoopApiService {
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    } else {
      firebase.app();
    }

    this.captcha = firebase.auth;
    this.auth = firebase.auth();
    this.functions = firebase.app().functions("asia-south1");
    this.db = firebase.firestore();
    this.fromDate = firebase.firestore.Timestamp.fromDate;
    this.storage = firebase.storage();
    this.analytics = firebase.analytics();
    //TODO: Need to add base URL from env file
    this.BASE_URL = process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL;
    this.DEFAULT_TIMEOUT = 30;
  }

  getHttpConfig = (token, verb = "GET") => ({
    method: verb,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    origin: "hr-app-dev.web.app",
  });

  getContentType = (res) => {
    const isJSON =
      res.headers.get("Content-Type")?.startsWith("application/json") || false;

    if (isJSON) {
      return "JSON";
    }

    const isText = res.headers.get("Content-Type")?.startsWith("text") || false;
    if (isText) {
      return "Text";
    }

    return "Unsupported";
  };

  processResponse = async (res) => {
    const contentType = this.getContentType(res);

    if (res.ok) {
      if (contentType === "JSON") {
        return await res.json();
      } else {
        return res;
      }
    }

    return this.doThrow(res, contentType);
  };

  doThrow = async (res, contentType) => {
    // Not 2XX
    // console.log('error: ', res);
    if (contentType === "JSON") {
      const result = await res.json();
      let error;
      if (Array.isArray(result) && result.length > 0) {
        error = result[0];
      } else {
        error = result;
      }
      throw new LoopHttpException(res.status, error.message, res.url);
    } else if (contentType === "Text") {
      // TODO: Check API to see if handled differently
      const error = await res.text();
      const message = error ? error : "";
      throw new LoopHttpException(res.status, message, res.url);
    }

    // Not JSON and not Text
    throw new LoopHttpException(
      res.status,
      "Unsupported Content Type",
      res.url
    );
  };

  api = async ({ endpoint, verb, token, timeout, data }) => {
    const reqConfig = this.getHttpConfig(token, verb);
    const fullUrl = `${this.BASE_URL}${endpoint}`;
    const reqTimeout = timeout || this.DEFAULT_TIMEOUT;
    const controller = new AbortController();
    let finalConfig;

    if (!data) {
      finalConfig = { signal: controller.signal, ...reqConfig };
    } else {
      finalConfig = {
        signal: controller.signal,
        body: JSON.stringify(data),
        ...reqConfig,
      };
    }
    const abort = setTimeout(() => controller.abort(), reqTimeout * 1000);
    const response = await fetch(fullUrl, finalConfig);
    clearTimeout(abort);
    return this.processResponse(response);
  };

  getUsers = async (companyId) => {
    const userdata = [];
    const dependents = [];
    const usersSnapshot = await this.db
      .collection("users")
      .where("employer", "==", companyId)
      .get();

    usersSnapshot.forEach((doc) => {
      userdata.push(doc.data());

      doc.data().dependents.map((dependentId) => {
        dependents.push(dependentId);
        return dependentId;
      });
    });

    return { userdata, dependents };
  };
  getCompanyData = async (companyId) => {
    const querySnapshot = await this.db
      .collection("company")
      .doc(companyId)
      .get();
    const company = querySnapshot.data();

    return company;
  };
  getUserData = async (userId, methodType) => {
    let data = [];
    try {
      //NOTE: Method type like how the user going to login e.g. using phone or email.
      const querySnapShot = await this.db
        .collection("customEnrolmentUser")
        .where("active", "==", true)
        .where(methodType, "==", userId)
        .get();
      querySnapShot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
    } catch (error) {
      console.log("get user data error:", error);
    }
    return (data && data.length && data[0]) || null;
  };

  updateHrData = async (id, data) => {
    let result = null;
    try {
      result = await this.db
        .collection("customEnrolmentUser")
        .doc(id)
        .update(data);
    } catch (error) {
      console.log("update hr data error:", error);
    }
    return result;
  };
  addEmpHrRequest = async (data) => {
    let result = null;
    try {
      result = await (await this.db.collection("hrRequests").add(data)).get();
      if (result) {
        result = result.data();
      }
    } catch (error) {
      console.log("add request error:", error);
    }
    return result;
  };
  addDependentInEmp = async (data) => {
    let result = null;
    try {
      result = await (await this.db.collection("hrRequests").add(data)).get();
      if (result) {
        result = result.data();
      }
    } catch (error) {
      console.log("add hr dependent error:", error);
    }
    return result;
  };
  updateEmpHrRequest = async (docRefId, data) => {
    let result = null;
    try {
      result = await this.db
        .collection("hrRequests")
        .doc(docRefId)
        .update(data);
    } catch (error) {
      console.log("update reqest failed:", error);
    }
    return result;
  };
  //Below method will add new depdendent inside the request employee which is in addition state.
  addDepdendentHrReq = async (docRef, data) => {
    let result = null;
    try {
      result = await this.db
        .collection("hrRequests")
        .doc(docRef)
        .update({
          dependents: firebase.firestore.FieldValue.arrayUnion(data),
        });
    } catch (error) {
      console.log("add/update depedent err:", error);
    }
    return result;
  };
  fetchHrRequestList = async (employerId) => {
    let result = null;
    try {
      result = await this.db
        .collection("hrRequests")
        .where("active", "==", true)
        .where("employerId", "==", employerId)
        .where("status", "==", AppConstants.PENDING)
        .get();
    } catch (error) {
      console.log("fetch hr request error:", error);
    }
    return result;
  };
  getEmployeeData = async (companyId) => {
    let empList = [];
    try {
      let querySnapshot = await this.db
        .collection("user")
        .where("active", "==", true)
        .where("employer", "==", companyId)
        .get();
      if (querySnapshot) {
        querySnapshot.forEach((item) => {
          empList.push({ docRefId: item.id, ...item.data() });
        });
      }
    } catch (error) {
      console.log("Fetch employee list:", error);
    }
    return empList;
  };
  getHrRequestData = async (companyId) => {
    let empPendingList = [];
    try {
      let result = await this.db
        .collection("hrRequests")
        .where("active", "==", true)
        .where("hrEmployerId", "==", companyId)
        .where("status", "==", AppConstants.PENDING)
        .get();
      if (result) {
        result.forEach((item) => {
          empPendingList.push({ docRefId: item.id, ...item.data() });
        });
      }
    } catch (error) {
      console.log("Hr request list error:", error);
    }
    return empPendingList;
  };
  fetchEmployeeData = async (userId, companyId) => {
    let employeeData = [];
    try {
      let result = await this.db
        .collection("user")
        .where("active", "==", true)
        .where("userId", "==", userId)
        .where("employer", "==", companyId)
        .get();
      if (result) {
        result.forEach((item) => {
          employeeData.push({ docRefId: item.id, ...item.data() });
        });
      }
    } catch (error) {
      console.log("fetch emp data:", error);
    }
    return employeeData && employeeData.length > 0 ? employeeData[0] : [];
  };
  getRequestedEmp = async (docRef) => {
    let result = null;
    try {
      result = await this.db.collection("hrRequests").doc(docRef).get();
      return result.data();
    } catch (error) {
      console.log("get emp api error:", error);
    }
    return result;
  };
  //Note: pagination for dependent fetch
  fetchDependentList = async (dependentIdArr) => {
    let dependentData = [];
    let count = 0;
    let index = 0;
    let maxCount = Math.ceil(dependentIdArr.length / 10);
    while (maxCount > count) {
      let dependentIdChunk = dependentIdArr.slice(index, index + 10);
      let result = await this.db
        .collection("user")
        .where("active", "==", true)
        .where("userId", "in", dependentIdChunk)
        .get();
      count++;
      index += 10;
      if (result) {
        result.forEach((item) => {
          dependentData.push({ docRefId: item.id, ...item.data() });
        });
      }
    }
    return dependentData;
  };
  fetchDependentApi = async (employerId) => {
    const idToken = await this.auth.currentUser.getIdToken();
    let result = await this.api({
      endpoint: LoopEndpoints.fetchDependentList(employerId),
      token: idToken,
    });
    return { data: result };
  };
  getPolicyList = async (data) => {
    const idToken = await this.auth.currentUser.getIdToken();
    return this.api({
      endpoint: LoopEndpoints.userPolicyDetails(),
      token: idToken,
    });
  };
  postEnrolmentData = async (data) => {
    const idToken = await this.auth.currentUser.getIdToken();
    return this.api({
      endpoint: LoopEndpoints.postEnrolmentData(),
      token: idToken,
      verb: "POST",
      data: data,
    });
  };
  getHrRequestDependent = async (parentId) => {
    let depPendingList = [];
    try {
      let result = await this.db
        .collection("hrRequests")
        .where("active", "==", true)
        .where("parentId", "==", parentId)
        .where("status", "==", AppConstants.PENDING)
        .get();
      if (result) {
        result.forEach((item) => {
          depPendingList.push({ docRefId: item.id, ...item.data() });
        });
      }
    } catch (error) {
      console.log("Hr request list error:", error);
    }
    return depPendingList;
  };
}

export default new LoopApiService();
