import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./EmployeeItem.css";
import * as moment from "moment";
import InsuranceCard from "./InsuranceCard";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  convertToDate,
  getStatus,
  getSuccessMessage,
} from "../../common/Utilities";
import { AddEmployeeForm } from "../AddEmployeeForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentOperation,
  selectCurrentOperation,
  setCurrentOperation,
  setEditForm,
} from "../../redux/slices/addEmpFormSlice";
import { AppConstants } from "../../common/AppConstants";
import Message from "../Message/Message";

export const EmployeeItem = ({
  firstName,
  lastName,
  employeeId,
  relationShip,
  dob,
  gender,
  number,
  email,
  isDependent = false,
  id,
  user,
  handleDeleteBackdrop,
  disable,
  operation,
  issuedOn,
  policyData,
}) => {
  const history = useHistory();
  const [formVisible, setFormVisible] = useState(false);
  const dispatch = useDispatch();
  const currentOperation = useSelector(selectCurrentOperation);
  // const handleEcardPress = (patientEcardUrl) => {
  //   if (true) {
  //     window.open(
  //       "https://firebasestorage.googleapis.com/v0/b/loophealth-patient-dev.appspot.com/o/Ecards%2F1234567846.pdf?alt=media&token=3303fe34-6ef6-48c7-8505-dc815c72d66e"
  //     );
  //   } else {
  //     alert("Ecard not found");
  //   }
  // };
  const handleEditPress = () => {
    setFormVisible(true);
    dispatch(setEditForm({ data: true }));
    let msg = getSuccessMessage(
      firstName,
      lastName,
      isDependent ? AppConstants.UPDATE_DEPENDENT : AppConstants.UPDATE
    );

    let body = {
      userId: user.userId,
      operation: AppConstants.UPDATE,
      msg: msg,
      error: false,
      success: false,
    };
    dispatch(setCurrentOperation({ data: body }));
  };
  return formVisible ? (
    <div className="mx-auto">
      <AddEmployeeForm
        employeeData={user}
        setVisibleForm={setFormVisible}
        isEdit={true}
        isDependent={isDependent}
        parentEmpId={employeeId}
        operation={AppConstants.UPDATE}
        userType={isDependent ? AppConstants.DEPENDENT : AppConstants.EMPLOYEE}
      />
    </div>
  ) : (
    <div className="emp-container">
      {user.userId === currentOperation.userId && currentOperation.success ? (
        <Message
          variant="success"
          onClose={() => dispatch(clearCurrentOperation())}
        >
          {currentOperation.msg}
        </Message>
      ) : null}
      {user.userId === currentOperation.userId && currentOperation.failed ? (
        <Message variant="danger">{currentOperation.msg}</Message>
      ) : null}
      {!disable && (
        <div className="emp-toolbar">
          <div
            onClick={() => {
              handleDeleteBackdrop(user);
            }}
          >
            <img
              style={{ marginLeft: "10px", cursor: "pointer" }}
              src="/assets/icons/delete.svg"
              alt="edit-icon"
            />
          </div>
          <div onClick={handleEditPress}>
            <img
              style={{ marginLeft: "10px", cursor: "pointer" }}
              src="/assets/icons/edit.svg"
              alt="edit-icon"
            />
          </div>
        </div>
      )}
      <div className="emp-body-container">
        <div style={{ flex: 1 }}>
          <div className="emp-row-container">
            <LabelText label="FIRST NAME" text={firstName} bold={true} />
            <LabelText label="LAST NAME" text={lastName} bold={true} />
          </div>
          {!isDependent && (
            <div className="emp-row-container">
              <LabelText label="EMPLOYEE ID" text={employeeId} />
              <LabelText label="RELATIONSHIP" text={relationShip} />
            </div>
          )}

          <div className="emp-row-container">
            <LabelText label="DOB" text={convertToDate(dob, "DD/MM/YYYY")} />
            <LabelText label="GENDER" text={gender} />
          </div>
          <div className="emp-row-container">
            <LabelText label="MOBILE NUMBER" text={number} />
            <LabelText label="EMAIL" text={email} />
          </div>
          <div className="emp-row-container">
            {isDependent && (
              <LabelText label="RELATIONSHIP" text={relationShip} />
            )}
            {disable && (
              <LabelText
                label="Status"
                text={AppConstants.PENDING}
                operation={operation}
              />
            )}
          </div>
        </div>
        {policyData && policyData.length > 0 ? (
          <div className="emp-policy-container">
            {policyData.map((item) => (
              <InsuranceCard
                policyType={item.policyType}
                insurerName={item.insurerName}
                eCardName={item.policyEcard}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const LabelText = ({ label, text, bold = false, operation }) => {
  return (
    <OverlayTrigger
      placement="bottom-start"
      overlay={
        <Tooltip hidden={!operation} id="edit-member-data">
          {getStatus(operation)}
        </Tooltip>
      }
    >
      <div className="label-text-container">
        <span className="emp-label-text">{label}</span>
        <span
          className={`${bold ? "emp-bold-text" : "emp-normal-text"} ${
            text && text.toLowerCase() === AppConstants.PENDING
              ? "text-orange"
              : ""
          }`}
        >
          {text}
        </span>
      </div>
    </OverlayTrigger>
  );
};
