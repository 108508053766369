import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';

import firebase, { auth } from '../firebase';

import fb from 'firebase';

import './Login.scss';
import { setLoopIdToken, verifyEmailLink } from '../common/CustomFirebase';
import { useHistory } from 'react-router-dom';
import LoopApiServiceContext from '../contexts/LoopApiServiceContext';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchHrUser,
    selectConfirmationResult,
    selectHrUser,
    setClearHrData,
    setConfirmationResult
} from '../redux/slices/usersSlice';
import Backdrop from '../components/UI/Backdrop/Backdrop';
import { InputText } from '../components/UI/InputText';
import { SideLayout } from '../components/UI/SideLayout';
import { validateMobile } from '../common/Utilities';

const Login = () => {
    const { setLoggedInUser } = useAuth();
    const [error, setError] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobileVerified, setMobileVerified] = useState(false);
    const history = useHistory();
    const firebaseContext = useContext(LoopApiServiceContext);
    const dispatch = useDispatch();
    const confirmationResult = useSelector(selectConfirmationResult);
    const microsoftProviderRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const hrData = useSelector(selectHrUser);

    useEffect(() => {
        microsoftProviderRef.current = new firebaseContext.captcha.OAuthProvider('microsoft.com');
        const db = firebaseContext.db;
        verifyEmailLink(setLoading, db, history, dispatch, setLoggedInUser);
    }, [firebaseContext.captcha.OAuthProvider, firebaseContext.db]);

    useEffect(() => {
        if (confirmationResult && mobile && mobile.length === 10 && mobileVerified) {
            history.push('/enter-otp', {
                mobile: mobile,
                verifyEmail: true
            });
            setMobileVerified(false);
        }
    }, [confirmationResult, history, mobile, mobileVerified]);

    useEffect(() => {
        if (hrData && hrData.error === '' && hrData.data && hrData.state === 'succeeded') {
            let data = hrData.data;
            if (data.email || data.mobile) {
                setLoopIdToken(hrData.data.userId);
                setLoggedInUser(data);
                setLoading(false);
                history.push('/');
            }
        } else if (hrData && hrData.error.length) {
            setLoading(false);
        }
    }, [hrData]);
    const loadOnRefresh = useCallback(() => {
        dispatch(setClearHrData());
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener('beforeunload', loadOnRefresh);
        return () => {
            window.removeEventListener('beforeunload', loadOnRefresh);
        };
    }, [loadOnRefresh]);

    // useEffect(() => {
    //     // window.recaptchaVerifier = new firebaseContext.captcha.RecaptchaVerifier('otp-button', {
    //     //     size: 'invisible'
    //     // });
    //     // window.recaptchaVerifier.render().then(function (widgetId) {
    //     //   window.recaptchaWidgetId = widgetId;
    //     // });
    // }, [firebaseContext.auth.RecaptchaVerifier, firebaseContext.captcha.RecaptchaVerifier]);

    const signInWithOutlook = () => {
        dispatch(setConfirmationResult({ data: null }));
        if (microsoftProviderRef.current) {
            firebase
                .auth()
                .signInWithPopup(microsoftProviderRef.current)
                .then((result) => {
                    setLoading(true);
                    /** @type {firebase.auth.OAuthCredential} */
                    let currentUser = firebase.auth().currentUser;
                    let email = result.user.providerData && result.user.providerData[0].email;
                    // OAuth access and id tokens can also be retrieved:
                    if (!currentUser.emailVerified) {
                        currentUser
                            .updateEmail(email)
                            .then(() => {
                                currentUser = firebase.auth().currentUser;
                                dispatch(
                                    fetchHrUser({
                                        methodType: 'email',
                                        userId: email,
                                        loginMethod: 'outlook'
                                    })
                                );
                            })
                            .catch((error) => {
                                setLoading(false);
                                console.log('error:', error);
                            });
                    } else {
                        if (email) {
                            dispatch(
                                fetchHrUser({
                                    methodType: 'email',
                                    userId: email,
                                    loginMethod: 'outlook'
                                })
                            );
                            setLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('microsoft login error:', error);
                    // Handle error.
                });
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='login-container'>
                <SideLayout />
                <div className='login right-box-content'>
                    <div className='lh-login-form'>
                        <h4 className='login-title'>Get started</h4>
                        <p className='intro-subtitle'>Login to view your details</p>
                        <div className='row'>
                            {/* <div className="col-md-6">
                <button
                  className={`btn lh-login-button mt-4 ${
                    hrData.loginMethod === "google" &&
                    hrData.error === "EMAIL_NOT_FOUND"
                      ? "error-login-button"
                      : ""
                  }`}
                  variant="info"
                  onClick={signInWithGoogle}
                >
                  <img
                    className="googleImage"
                    src="/assets/icons/googleLogo.svg"
                    alt=""
                  />
                  Continue with Google
                </button>
                {hrData.loginMethod === "google" &&
                  hrData.error === "EMAIL_NOT_FOUND" && (
                    <div className="button-error-text">User Not Registered</div>
                  )}
              </div> */}
                            <div className='col-md-12'>
                                <button
                                    className={`btn lh-login-button mt-4 ${
                                        hrData.loginMethod === 'outlook' && hrData.error === 'EMAIL_NOT_FOUND'
                                            ? 'error-login-button'
                                            : ''
                                    }`}
                                    variant='info'
                                    onClick={signInWithOutlook}
                                >
                                    <img className='googleImage' src='/assets/images/outlook.svg' alt='' />
                                    Continue with Outlook
                                </button>
                                {hrData.loginMethod === 'outlook' && hrData.error === 'EMAIL_NOT_FOUND' && (
                                    <div className='button-error-text'>User Not Registered</div>
                                )}
                            </div>
                        </div>

                        {/* <div className="row">
              <div className="col-md-12">
                <h6 className="hr-line">
                  <span>Or</span>
                </h6>
              </div>
            </div> */}

                        {/* {error && <Alert variant='danger'>{error}</Alert>}

                        <InputText
                            placeholder='Enter Mobile'
                            value={mobile}
                            setValue={setMobile}
                            maxLength='10'
                            isInvalid={hrData.error === 'MOBILE_NOT_FOUND'}
                            invalidText={
                                hrData.error === 'NOT_FOUND' ? 'Number not found' : 'Please enter a valid mobile number'
                            }
                        /> */}
                        {/* <Button
                            id='otp-button'
                            className='lh-login-submit-button'
                            disabled={!validateMobile(mobile)}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    const confirmationData = await auth.signInWithPhoneNumber(
                                        '+91' + mobile,
                                        window.recaptchaVerifier
                                    );
                                    dispatch(setConfirmationResult({ data: confirmationData }));
                                    setMobileVerified(true);
                                    setLoading(false);
                                } catch (error) {
                                    setLoading(false);
                                    alert('Error: ' + error?.message);
                                    console.log('Error in sign in with phone:', error);
                                }
                            }}
                        >
                            Get OTP
                        </Button> */}
                    </div>
                    With the help of Loop’s enrolment portal, you can easily make your insurance and top-up selections
                    <div />
                </div>
                {(loading || hrData.state === 'loading') && <Backdrop />}
            </div>
        </>
    );
};

export default Login;
